import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import "./../assets/css/custom.css";

const SettingTextField = (props) => {
  const theme = createTheme({
    palette: {
      gray: {
        main: "#868E96",
      },
    },
  });

  let bgColor =
    props?.editable != false
      ? props?.backgroundColor
        ? props.backgroundColor
        : "#FFFFFF"
      : props?.disabledBg
      ? props.disabledBg
      : "#DDDDDD";
  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[styles.labelText, props?.labelStyle ? props.labelStyle : null]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          ...styles.textInputView,
          pl: props?.leftView ? 1.5 : 0,
          pr: props?.rightView ? 1.5 : 0,
          backgroundColor: bgColor,
          border: props?.border ? props.border : "0px",
          borderRadius: props?.borderRadius ? props.borderRadius : "12px",
          width: props?.width ? props.width : "100%",
        }}
      >
        <ThemeProvider theme={theme}>
          <TextField
            required={props?.required ? true : false}
            disabled={!(props?.editable == false ? false : true)}
            fullWidth
            label={
              props?.placeholder && props.value == "" ? props?.placeholder : ""
            }
            type={props?.type ? props.type : "default"}
            color="gray"
            variant={props?.variant ? props?.variant : "standard"}
            value={props.value}
            onChange={(e) => props.setValue(e.target.value)}
            sx={{
              "& .MuiInputBase-root": {
                "&:hover": {
                  backgroundColor: bgColor,
                },
                "&.Mui-focused": {
                  backgroundColor: bgColor,
                },
                "&.Mui-disabled": {
                  backgroundColor: bgColor,
                },
                "&:before, :after, :hover:not(.Mui-disabled):before": {
                  borderBottom: 0,
                },
              },
              "& .MuiInputAdornment-positionStart": {
                margin: "0 !important",
              },
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: props?.disabledColor
                  ? `${props?.disabledColor} !important`
                  : "rgba(0, 0, 0, 0.38)",
              },
              "& .MuiFilledInput-input": {
                paddingTop: "0px !important",
                paddingBottom: "0px !important",
                px: props?.px ? props.px : 1.5,
                textAlign: props?.textAlign ? props.textAlign : "left",
              },
              ...props?.textInputCss,
            }}
            InputLabelProps={{
              shrink: true,
              sx: {
                ...styles.inputLabelStyle,
                color: props?.placeholderColor
                  ? props.placeholderColor
                  : "#AAAAAA",
                mt: props?.marginTop ? props.marginTop : 0.8,
                "& .MuiFormLabel-asterisk": {
                  display: "none",
                },
              },
            }}
            InputProps={{
              autoComplete: "none",
              sx: {
                ...styles.textInputValue,
                backgroundColor: bgColor,
                height: props?.height ? props.height : 46,
                fontSize: props?.inputFontSize ? props.inputFontSize : 15,
                ...props?.inputTextStyle,
              },
              startAdornment: props?.leftView ? (
                <InputAdornment position="start">
                  {props.leftView}
                </InputAdornment>
              ) : null,
              endAdornment: props?.rightView ? (
                <InputAdornment position="end">
                  {props.rightView}
                </InputAdornment>
              ) : null,
            }}
          />
        </ThemeProvider>
      </Box>
      <Box>
        {props?.error ? (
          <Typography sx={styles.errorText}>{props.error}</Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default SettingTextField;

const styles = {
  labelText: {
    color: "#272D2F",
    fontSize: 15,
    fontFamily: "GilroySemiBold",
  },
  textInputView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "12px",
    overflow: "hidden",
  },
  inputLabelStyle: {
    fontSize: 17,
    fontFamily: "GilroyMedium",
    px: 0,
    mt: 0.8,
  },
  textInputValue: {
    height: 46,
    color: "#000000",
    fontFamily: "GilroySemiBold",
    px: 0,
  },
  errorText: {
    color: "#CD0000",
    fontSize: 10,
    fontFamily: "GilroyMedium",
  },
};
