import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {CURRENCY_SYMBOL} from "../../constants";


const symbol = CURRENCY_SYMBOL;

const SearchItem = (props) => {
  let data = props.data;

  return (
    <Grid container direction="row" item xs={12}
          sx={{borderBottom: props.lastIndex ? '0px' : '1px solid #EEE', pb: 1, ...styles.mainCard}}>
      <Grid xs={3} md={2}>
        <Box sx={styles.imgView}>
          <img alt="" src={data.images[0]} style={styles.imgStyle}/>
        </Box>
      </Grid>
      <Grid xs={7} md={8} sx={{ml: 2}}>
        <Typography sx={styles.nameText}>
          {data.name}
        </Typography>
        <Grid sx={{position: 'relative', width: '100%',}}>
          <Typography sx={styles.descText}>
            {data.description}
          </Typography>
        </Grid>
        <Typography sx={styles.priceText}>
          {props.type == 'menu' ? `${symbol[data.price.currency]}${data.price.value}` :
            (data.prize.length > 0 ?
              ((typeof CURRENCY_SYMBOL[data.prize[0]['currency']] == 'undefined' ? '' : CURRENCY_SYMBOL[data.prize[0]['currency']])
                + ' ' + data.prize[0]['value']) : '$ ' + 0)
          }
        </Typography>
      </Grid>
    </Grid>
  );
}

export default SearchItem;

const styles = {
  mainCard: {
    alignItems: 'center',
    px: 1.5,
    pt: 1.5,
  },
  imgView: {
    width: 50,
    height: 50,
    borderRadius: 2,
    overflow: 'hidden'
  },
  imgStyle: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
  nameText: {
    fontFamily: 'GilroyBold',
    fontSize: 12,
  },
  descText: {
    color: '#999',
    fontFamily: 'GilroyRegular',
    fontSize: 10,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  priceText: {
    fontSize: 13,
    fontFamily: 'GilroyBold'
  },
};
