import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./CategoryReorder.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import EditCategoryOrder from "../../components/restaurant/EditCategoryOrder";
import RestaurantHeader from "../../components/RestaurantHeader";

const CategoryReorder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [catList, setCatList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let params = location.pathname.split("/");
    if (params.length >= 4) {
      getCategoryList();
    } else {
      setIsLoading(false);
    }
  }, []);

  const getCategoryList = () => {
    setIsLoading(true);
    const filter = `pageSize=100000&pageNum=1&filter__idRestaurant=${restaurantId}`;

    Api.getMenuCategoryList(filter).then((response) => {
      if (response.success) {
        setCatList(response.data.rows.sort((a, b) => a?.order - b?.order));
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 200 }} />
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={12} mb={2}>
            <RestaurantHeader
              title={"Re-Order Category"}
              onBackBtnClick={() =>
                navigate("/restaurant/" + restaurantId + "/settings/category")
              }
            />
          </Grid>
          <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
            <Grid item xs={7}>
              <EditCategoryOrder
                restaurantId={restaurantId}
                categoryList={catList}
                setMsgAlert={setMsgAlert}
                onCancel={() =>
                  navigate("/restaurant/" + restaurantId + "/settings/category")
                }
                onSubmit={() =>
                  navigate("/restaurant/" + restaurantId + "/settings/category")
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default CategoryReorder;
