import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./GiftCardTransactions.module.css";

import { connect } from "react-redux";

import Container from "../../../components/Container";
import GiftCardHistoryTable from "../../../components/GiftCardHistoryTable";

import { THEME_COLOR, BTN_COLOR } from "../../../constants";

import { ReactComponent as LeftArrowIcon } from "../../../assets/images/left_arrow1.svg";

let LISTS = [
  {
    _id: "1",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "top_up",
  },
  {
    _id: "2",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "redeemed",
  },
  {
    _id: "3",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "top_up",
  },
  {
    _id: "4",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "redeemed",
  },
  {
    _id: "5",
    name: "John Wick",
    email: "johnwick@gmail.com",
    date: "16-06-2023",
    amount: "10",
    status: "redeemed",
  },
];

let FILTER_LIST = [
  { _id: "all", name: "All" },
  { _id: "redeemed", name: "Redeemed" },
  { _id: "top_up", name: "Top up" },
];

const GiftCardTransactions = (props) => {
  const [activeTab, setActiveTab] = useState("all");
  const [historyList, setHistoryList] = useState(LISTS);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel="Restaurants"
    >
      <Grid container display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Box
            sx={{ backgroundColor: THEME_COLOR }}
            className={styles.leftArrowBox}
            onClick={() => navigate(-1)}
          >
            <LeftArrowIcon height={15} width={15} />
          </Box>
          <Typography variant={"h6"} className={styles.restaurantInfo} ml={2}>
            Gift Card Transactions - {props.restaurantData?.name}
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ backgroundColor: "#fff", borderRadius: 2 }}
        >
          {FILTER_LIST.map((x, i) => {
            return (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                key={i}
                onClick={() => setActiveTab(x._id)}
                className={styles.transactionsBtn}
                sx={{
                  backgroundColor: activeTab === x._id ? BTN_COLOR : "#fff",
                  minWidth: 100,
                }}
              >
                <Typography
                  className={styles.giftCardStatusBtnText}
                  sx={{ color: activeTab === x._id ? "#fff" : "#777" }}
                >
                  {x.name}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Grid>

      <Grid container item xs={12} mt={4}>
        <GiftCardHistoryTable
          itemList={historyList.filter(
            (x) => x.status == activeTab || activeTab == "all"
          )}
        />
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantData: state.userData.restaurantData,
  };
};

export default connect(mapStateToProps, null)(GiftCardTransactions);
