import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./AccountDetail.module.css";

import Container from "../../components/Container";
import SettingTextField from "../../components/SettingTextField";
import SettingSelectField from "../../components/SettingSelectField";
import RestaurantHeader from "../../components/RestaurantHeader";

let country_list = [
  { label: "UK", value: "UK" },
  { label: "UAE", value: "UAE" },
  { label: "Saudi Arabia", value: "Saudi Arabia" },
  { label: "India", value: "India" },
];

const AccountDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );

  const [loading, setLoading] = useState(false);
  const [countryVal, setCountryVal] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [iban, setIban] = useState("");

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      <Grid container item xs={12}>
        <Grid item xs={12} mb={2}>
          <RestaurantHeader
            title={"Account Details"}
            onBackBtnClick={() => navigate("/restaurant/" + restaurantId)}
          />
        </Grid>
        <Grid item xs={12} className={styles.mainView} px={3} pt={2}>
          <Grid container item xs={12} md={8}>
            <Grid container item xs={12} mt={2}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography className={styles.textInputLabel}>
                  Country
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SettingSelectField
                  page={"settings"}
                  value={countryVal}
                  setValue={setCountryVal}
                  border={"1px solid #AAA"}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  menuList={country_list}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} mt={2}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography className={styles.textInputLabel}>
                  Beneficiary Name
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SettingTextField
                  page={"accountDetail"}
                  value={beneficiaryName}
                  setValue={setBeneficiaryName}
                  border={"1px solid #AAA"}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} mt={2}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography className={styles.textInputLabel}>
                  Account Number
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SettingTextField
                  page={"accountDetail"}
                  value={accountNumber}
                  setValue={setAccountNumber}
                  border={"1px solid #AAA"}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} mt={2}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography className={styles.textInputLabel}>
                  Sort Code
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SettingTextField
                  page={"accountDetail"}
                  value={sortCode}
                  setValue={setSortCode}
                  border={"1px solid #AAA"}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} mt={2}>
              <Grid item xs={4.5} className={styles.textInputLabelView}>
                <Typography className={styles.textInputLabel}>IBAN</Typography>
              </Grid>
              <Grid item xs={6}>
                <SettingTextField
                  page={"accountDetail"}
                  value={iban}
                  setValue={setIban}
                  placeholder={""}
                  border={"1px solid #AAA"}
                  required={true}
                  variant={"filled"}
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} mt={2}>
              <Grid item xs={4.5} className={styles.textInputLabelView}></Grid>
              <Grid item xs={6}>
                <Box className={styles.btnMainView}>
                  <Button type="button" className={styles.editBtn}>
                    Edit
                  </Button>
                  <Button
                    type="button"
                    className={styles.saveBtn}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#000 !important",
                      },
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AccountDetail;
