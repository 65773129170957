import React from "react";
import Grid from "@mui/material/Grid";

import Container from "../../components/Container";
import AddRestaurant from "../../components/restaurant/AddRestaurant";

const AddRestaurantInfo = () => {
  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <AddRestaurant type={"add"} resData={""} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddRestaurantInfo;
