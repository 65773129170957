import { Outlet, useParams } from "react-router-dom";
import React, { useEffect } from "react";

import * as Api from "../api";

import { connect } from "react-redux";
import { updateRestaurantData } from "../redux/actions/userDataActions";

const RestIdLayout = (props) => {
  const { rest_id } = useParams();

  useEffect(() => {
    if (rest_id && props.restaurantData?._id == rest_id) {
      document.title = `Foodo - ${props?.restaurantData?.name}`;
    } else {
      document.title = `Foodo - Admin`;
    }
  }, [props.restaurantData?.name, rest_id]);

  useEffect(() => {
    if (rest_id && props.restaurantData?._id != rest_id) {
      getRestaurantData();
    }
  }, [rest_id]);

  const getRestaurantData = () => {
    Api.getRestaurantData(rest_id).then((response) => {
      if (response.success) {
        props.updateRestaurantData(response.data);
      }
    });
  };

  return <Outlet />;
};

const mapStateToProps = (state) => {
  return {
    restaurantData: state.userData.restaurantData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantData: (data) => dispatch(updateRestaurantData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestIdLayout);
