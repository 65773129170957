import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";

import styles from "./AddModifierItemModal.module.css";

import * as Api from "../../api";

import CategoryMenuList from "./CategoryMenuList";
import AlertMsg from "../AlertMsg";
import Loader from "../Loader";

const AddModifierItemModal = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const [categoryMenuList, setCategoryMenuList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    if(props.modifierData?.items?.length) {
      setSelectedMenuItems(props.modifierData.items);
    } else {
      setSelectedMenuItems([]);
    }
  }, [props.modifierData])

  useEffect(() => {
    if (
      props.modalVisible &&
      categoryMenuList.length == 0 &&
      props.menuList.length == 0
    ) {
      getMenuList(props.categoryList);
    } else if (
      props.modalVisible &&
      categoryMenuList.length == 0 &&
      props.categoryList.length >= 0 &&
      props.menuList.length >= 0
    ) {
      setIsLoading(true);
      let list = [];
      let data = [...props.menuList];
      props.categoryList.forEach((x) => {
        let cat_menu = {
          ...x,
          menu: data.filter((y) => y._idCategory == x._id),
        };
        list.push(cat_menu);
      });
      list = list.filter((y) => y.menu.length > 0);
      setCategoryMenuList(list);
      setIsLoading(false);
    }
  }, [props.modalVisible]);

  const getMenuList = (cat_list) => {
    setIsLoading(true);
    let filter =
      "?pageSize=300000&pageNum=1&filter__idRestaurant=" + props.restaurantId + "&orderBy=order&orderByDir=asc";
    Api.getMenuList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        let list = [];
        cat_list.forEach((x) => {
          let cat_menu = {
            ...x,
            menu: data.filter((y) => y._idCategory == x._id),
          };
          list.push(cat_menu);
        });
        list = list.filter((y) => y.menu.length > 0);
        setCategoryMenuList(list);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Modal
      className={styles.modelView}
      open={props.modalVisible}
      onClose={() => null}
    >
      <Grid
        item
        xs={12}
        className={styles.mainView}
        py={1}
        px={2}
        sx={{
          "&:focus": {
            outline: "none",
          },
          m: { md: "20px", xs: "10px" },
        }}
      >
        {isLoading ? (
          <Loader height={{ height: window.innerHeight - 200 }} />
        ) : (
          <>
            <CategoryMenuList
              page={"AddModifierItemModal"}
              restaurantId={props.restaurantId}
              categoryMenuList={categoryMenuList}
              searchMenu={""}
              tabWidth={window.innerWidth - 200}
              selectedMenuItems={selectedMenuItems}
              inititalSelectedItems={props.modifierData?.items?.length ? props.modifierData?.items : []}
              onSelectMenu={setSelectedMenuItems}
              allowSort={false}
              showGridIcon={false}
              showMenuPopup={false}
            />
            <Grid mt={1} container direction="row" justifyContent="center" py={2}>
              <Button
                type="button"
                className={styles.cancelBtn}
                onClick={() => props.onCancel()}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className={styles.saveBtn}
                onClick={() => props.onSubmit(selectedMenuItems)}
              >
               Add to Modifier
              </Button>
            </Grid>
          </>
        )}

        <AlertMsg
          msgAlert={msgAlert}
          onCloseAlertMsg={() =>
            setMsgAlert({ open: false, message: "", msgType: "error" })
          }
        />
      </Grid>
    </Modal>
  );
};

export default AddModifierItemModal;
