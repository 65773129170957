import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import * as Api from "../../api";

import Container from "../../components/Container";
import AddEvents from "../../components/AddEvent";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";

import '../../assets/css/custom.css';

import {THEME_COLOR} from "../../constants";

import {ReactComponent as LeftArrowIcon} from '../../assets/images/left_arrow1.svg';

const AddEvent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [restaurantList, setRestaurantList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({open: false, message: '', msgType: ''});

  const navigate = useNavigate();

  useEffect(() => {
    getRestaurantList();
  }, []);

  const getRestaurantList = () => {
    Api.restaurantList('/all').then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setRestaurantList(data);
        getEventCategoryList();
      } else {
        setIsLoading(false);
        setMsgAlert({open: true, message: response.msg, msgType: 'error'});
      }
    });
  };

  const getEventCategoryList = () => {
    Api.eventCategoryList('/all').then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setCategoryList(data);
      } else {
        setMsgAlert({open: true, message: response.msg, msgType: 'error'});
      }
      setIsLoading(false)
    });
  };

  const onCreateEvent = (data) => {
    let datas = JSON.stringify({
      name: data.name,
      _idCategory: data.category,
      _idRestaurants: data.restaurants,
      description: data.description,
      location: data.location,
      images: data.images,
      prize: [{
        value: data.price,
        currency: data.priceUnit
      }],
      eventStartDate: data.startDate,
      eventEndDate: data.endDate,
    });
    setIsLoading(true);
    Api.createEvent(datas).then((response) => {
      if (response.success) {
        setMsgAlert({open: true, message: response.msg, msgType: 'success'});
        navigate('/event')
      } else {
        setMsgAlert({open: true, message: response.msg, msgType: 'error'});
      }
      setIsLoading(false)
    });
  };

  return (
    <Container page={"events"} searchVal={''} onSearch={() => null} searchLabel="Events">
      <Grid sx={{position: 'relative'}}>
        {isLoading ?
          <Grid sx={styles.loader}>
            <Loader height={{height: '100%'}}/>
          </Grid> : null}
        <Grid container direction="row" alignItems="center">
          <Box
            sx={styles.leftArrowBox}
            onClick={() => navigate('/events')}>
            <LeftArrowIcon height={15} width={15}/>
          </Box>
          <Typography variant={'h6'} sx={styles.createEvent} ml={2}>Create Event</Typography>
        </Grid>
        <Grid container
              alignItems="center"
              justifyContent="center"
              mt={3}>
          <Card elevation={0} className="add-card">
            <Grid container
                  direction="column"
                  style={{width: '95%'}} mt={4}>
              <AddEvents
                isLoading={isLoading}
                restaurantList={restaurantList}
                categoryList={categoryList}
                onSubmit={onCreateEvent}/>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={() => setMsgAlert({open: false, message: '', msgType: 'error'})}/>
    </Container>
  )
};

export default AddEvent;


const styles = {
  loader: {
    backgroundColor: '#EEE',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    left: 0,
    right: 0,
    zIndex: 5,
  },
  leftArrowBox: {
    backgroundColor: THEME_COLOR,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 2,
    px: 1.3,
    py: 0.5,
  },
  createEvent: {
    fontFamily: 'GilroyBold',
  },
  btnText: {
    backgroundColor: THEME_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    fontFamily: 'GilroyBold',
    fontSize: 13,
    borderRadius: 1,
    width: 100,
    height: 35,
  }
}
