import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';

import Sidebar from "./../components/Sidebar";

function Container(props) {
  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline/>
      <Sidebar {...props}/>
      <Box component="main" sx={{flexGrow: 1, p: 3, backgroundColor:'#EEE'}}>
        <Toolbar/>
        {props.children}
      </Box>
    </Box>
  );
}

export default Container;
