import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {connect} from 'react-redux';
import {logOut} from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import Loader from "../../components/Loader";

const Logout = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    userLogout();
  });

  const userLogout = () => {
    Api.logout().then((response) => {
      if (response.success) {
        props.logOut();
        navigate("/");
      }
    });
  }

  return (
    <Loader height={{height: window.innerHeight}}/>
  )
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut())
  }
};

export default connect(null, mapDispatchToProps)(Logout);
