import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

import styles from "./AddMenu.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import AddSettingItem from "../../components/restaurant/AddSettingItem";
import Loader from "../../components/Loader";
import ArModelView from "./ArModelView";
import RestaurantHeader from "../../components/RestaurantHeader";

const AddMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("edit_menu");
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length >= 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [menuData, setMenuData] = useState(null);
  const [modelData, setModelData] = useState(null);
  const [restaurantDetail, setRestaurantDetail] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getRestaurantData();
  }, []);

  const getRestaurantData = () => {
    setIsLoading(true);
    Api.getRestaurantData(restaurantId).then((response) => {
      if (response.success) {
        let resData = response.data;
        setRestaurantDetail(resData);
        let params = location.pathname.split("/");
        if (params.length >= 5) {
          let menuId = params[4];
          getMenuData(menuId);
        } else {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const getMenuData = (menuId) => {
    setIsLoading(true);
    Api.getMenuData(menuId).then((response) => {
      if (response.success) {
        let data = response.data;
        let imageList = data.images.map((x) => {
          let index = x.lastIndexOf("/") + 1;
          let filename = x.substr(index);
          return {
            id: new Date(),
            name: filename,
            displayName: filename,
            size: 0,
            image: x,
            upload: true,
          };
        });
        data._id = menuId;
        data.images = imageList;
        setMenuData(data);
        if (data?.modelId) {
          getArModelData(data?.modelId);
        } else {
          setIsLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setIsLoading(false);
      }
    });
  };

  const getArModelData = (model_id) => {
    setIsLoading(true);
    Api.getArModelData(model_id).then((response) => {
      if (response.success) {
        let data = response.data;
        setModelData(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={12} mb={2}>
            <RestaurantHeader
              title={`${menuData ? "Edit" : "Add"} Menu`}
              onBackBtnClick={() =>
                navigate("/restaurant/" + restaurantId + "/settings")
              }
            />
          </Grid>
          {/*{menuData?.modelId ? (*/}
          {/*  <Box display="flex" alignItems="center" ml={1}>*/}
          {/*    <Button*/}
          {/*      type="button"*/}
          {/*      className={styles.tabBtn}*/}
          {/*      sx={{*/}
          {/*        mr: 1,*/}
          {/*        color: "#000",*/}
          {/*        backgroundColor:*/}
          {/*          selectedTab == "edit_menu" ? "#FFF" : "transparent",*/}
          {/*        "&:hover": {*/}
          {/*          backgroundColor: "#AAA !important",*/}
          {/*        },*/}
          {/*      }}*/}
          {/*      onClick={() => setSelectedTab("edit_menu")}*/}
          {/*    >*/}
          {/*      Edit Menu*/}
          {/*    </Button>*/}
          {/*    <Button*/}
          {/*      type="button"*/}
          {/*      className={styles.tabBtn}*/}
          {/*      sx={{*/}
          {/*        color: "#000",*/}
          {/*        backgroundColor:*/}
          {/*          selectedTab == "ar_view" ? "#FFF" : "transparent",*/}
          {/*        "&:hover": {*/}
          {/*          backgroundColor: "#AAA !important",*/}
          {/*        },*/}
          {/*      }}*/}
          {/*      onClick={() => setSelectedTab("ar_view")}*/}
          {/*    >*/}
          {/*      Edit AR Model*/}
          {/*    </Button>*/}
          {/*  </Box>*/}
          {/*) : null}*/}
          <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
            <AddSettingItem
              page={"AddMenu"}
              itemData={menuData}
              restaurantId={restaurantId}
              selectedTab={selectedTab}
              modelData={modelData}
              currencyIcon={restaurantDetail?.currecnyIcon}
              restaurantDetail={restaurantDetail}
              onCancel={() =>
                navigate(
                  menuData?._idCategory
                    ? `/restaurant/${restaurantId}/settings#${menuData?._idCategory}`
                    : `/restaurant/${restaurantId}/settings`
                )
              }
              onSubmit={() =>
                navigate(
                  menuData?._idCategory
                    ? `/restaurant/${restaurantId}/settings#${menuData?._idCategory}`
                    : `/restaurant/${restaurantId}/settings`
                )
              }
            />
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default AddMenu;
