import React from 'react';
import Grid from '@mui/material/Grid';

import * as Api from '../api/index';

import UploadFile from "./UploadFile";
import AddMenuImageItem from "./AddMenuImageItem";

import './../assets/css/custom.css';

const AddEventImagesUpload = (props) => {
  const uploadFile = (data, file) => {
    props.uploadFile(data, true, 'loading');

    let formData = new FormData();
    formData.append('image', file);

    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        data['name'] = response.data.fileId;
        data['upload'] = true;
        props.uploadFile(data, false, 'success');
      } else {
        props.uploadFile(data, false, 'error');
        props.onSetAlertMsg({open: true, message: response.msg, msgType: 'error'});
      }
    });
  };

  const onRemoveImage = (data) => {
    props.uploadFile(data, false, 'error');
  };

  const uploadError = (err) => {
    props.onSetAlertMsg({open: true, message: err, msgType: 'error'});
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      item xs={12}>
      <Grid item xs={12} md={props.imageList.length > 0 ? 5.5 : 12}>
        <UploadFile
          page={'addEventImg'}
          uploadText={'Drag and Drop Your Images Here'}
          imageLoading={props.imageLoading}
          maxFiles={5}
          uploadError={uploadError}
          uploadFile={uploadFile}/>
      </Grid>
      <Grid container
            direction="column"
            item xs={12} md={5.5}
            sx={{marginTop: {xs: 5, md: 2}, paddingLeft: 0, paddingRight: 0}}>
        {props.imageList.map((item, index) => {
          return (
            <AddMenuImageItem
              key={index}
              data={item}
              onRemoveImage={onRemoveImage}
            />
          )
        })}
      </Grid>
    </Grid>
  );
};

export default AddEventImagesUpload;
