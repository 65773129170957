import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Switch from '@mui/material/Switch';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import RestaurantBtn from "./RestaurantBtn";

import {THEME_COLOR, THEME_COLOR3} from "../constants";
import { allDays } from "./restaurant/AddRestaurant";

import './../assets/css/custom.css';

const theme = createTheme({
  palette: {
    gray: {
      main: THEME_COLOR3,
    },
    red: {
      main: '#FF0000',
    },
  },
});

const TimePickerSlotProps = {
  actionBar: {
    sx: {
      "& .MuiButton-root": {
        color: "#000",
        fontFamily: "GilroySemiBold",
      },
    },
  },
  digitalClockSectionItem: {
    sx: {
      fontSize: 13,
      fontFamily: "GilroySemiBold",
      "&.Mui-selected": {
        backgroundColor: `${THEME_COLOR}`,
        color: "#000",
        "&:hover": {
          backgroundColor: `${THEME_COLOR}`,
          color: "#000",
        },
      },
    },
  },
  textField: {
    color: "gray",
    variant: "standard",
    InputLabelProps: {
      style: {fontFamily: "GilroyRegular"},
    },
    inputProps: {
      readOnly: true,
      sx: {
        height: 30,
        fontSize: 13,
        fontFamily: "GilroySemiBold",
      },
    },
    sx: {
      svg: {color: "#000", fontSize: 18},
      "& .MuiButtonBase-root": {
        width: 35,
        height: 35,
      },
    },
  },
};

const AddRestaurantInfo = (props) => {
  const [openingHourList, setOpeningHourList] = useState(props.openingHourList);

  const onActiveSwitch = (data) => {
    let openingHour = [...openingHourList];
    let openingHourIndex = openingHour.findIndex((item) => item.day === data.day);
    if (openingHourIndex >= 0) {
      const active = !openingHour[openingHourIndex]['active'];
      openingHour[openingHourIndex]['active'] = active;
      if (active) {
        const {open, close} = openingHourList?.find(x => x?.day == allDays);
        openingHour[openingHourIndex]['open'] = open;
        openingHour[openingHourIndex]['close'] = close;
      }
      setOpeningHourList(openingHour);
    }
  };

  const openCloseTime = (val, data, type) => {
    let openingHour = [...openingHourList];
    if(data.day == allDays) {
      openingHour = openingHour.map(item => {
        item[type] = val;
        return item;
      });
    } else {
      let openingHourIndex = openingHour.findIndex((item) => item.day === data.day);
      if (openingHourIndex >= 0) {
        openingHour[openingHourIndex][type] = val;
      }
    }
    setOpeningHourList(openingHour);
  };

  const onSaveBtnClick = () => {
    props.resOpeningHours(openingHourList);
  };

  return (
    <Grid>
      <Grid
        container
        item
        component="form"
        noValidate
        autoComplete="off"
        justifyContent="center">
        {openingHourList.map((item, index) => {
          return (
            <Grid container item xs={9} key={index} sx={{alignItems: 'center', minHeight: 60}} mt={2}>
              <Grid container direction="row" item xs={12} md={4} mt={2}>
                <Grid item xs={12} md={6}>
                  <label className="day-label" style={{fontFamily: 'GilroySemiBold'}}>{item.day}</label>
                </Grid>
                <Grid item xs={12} md={6}>
                  {item?.day == allDays ? null :
                    <ThemeProvider theme={theme}>
                      <Switch
                        checked={item.active}
                        onChange={() => onActiveSwitch(item)}
                        color={'red'}
                        size="small"
                        sx={{
                          '& .MuiSwitch-track': {
                            height: 15,
                            backgroundColor: THEME_COLOR3 + ' !important',
                            marginTop: -0.3,
                          },
                          '& .MuiSwitch-thumb': {
                            backgroundColor: '#999',
                          },
                          '& .Mui-checked .MuiSwitch-thumb': {
                            backgroundColor: 'red !important',
                          },
                        }}
                      />
                    </ThemeProvider>}
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={12} md={7}>
                {item.active == 1 ?
                  (<ThemeProvider theme={theme}>
                    <Grid item xs={12} md={6} sx={{paddingRight: 5, marginTop: {xs: 2, md: 0}}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time Open"
                          value={item.open}
                          format="hh:mm aa"
                          onChange={(val) => openCloseTime(val, item, 'open')}
                          slotProps={TimePickerSlotProps}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{paddingRight: 5, marginTop: {xs: 2, md: 0}}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <TimePicker
                          label="Time Closed"
                          value={item.close}
                          format="hh:mm aa"
                          onChange={(val) => openCloseTime(val, item, 'close')}
                          slotProps={TimePickerSlotProps}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </ThemeProvider>)
                  : null}
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <RestaurantBtn activeTab={2} loading={false} previousBtnClick={props.previousBtnClick} onSaveBtnClick={onSaveBtnClick}/>
    </Grid>

  );
};

export default AddRestaurantInfo;
