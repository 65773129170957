import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";

import styles from "./ArModels.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";
import RestaurantHeader from "../../components/RestaurantHeader";

import { ReactComponent as MenuImgIcon } from "../../assets/images/menu_img.svg";
import { ReactComponent as ExpandVerticalIcon } from "../../assets/images/expand_vertical.svg";

import { CURRENCY_SYMBOL } from "../../constants";

const ArModels = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [menuList, setMenuList] = useState([]);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getMenuList();
  }, []);

  const getMenuList = () => {
    let filter =
      "?pageSize=300000&pageNum=1&filter__idRestaurant=" +
      restaurantId +
      "&orderBy=modelOrder&orderByDir=asc";
    Api.getMenuList(filter).then((response) => {
      if (response.success) {
        setMenuList(
          response.data.rows
            .filter((x) => x.modelId !== null)
            .sort((a, b) => a?.modelOrder - b?.modelOrder)
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setIsLoading(true);
    let menu_list = [...menuList];
    let new_list = arrayMoveImmutable(menu_list, oldIndex, newIndex);

    let order_list = new_list.map((x, i) => {
      return { ...x, modelOrder: i + 1 };
    });

    let data_list = order_list.map((x, i) => {
      return { order: x.modelOrder, _idMenuItem: x._id };
    });
    setMenuList(order_list);

    let data = JSON.stringify(data_list);
    Api.menuModelRearrange(data, restaurantId).then((response) => {
      if (!response.success) {
        setMenuList(menu_list);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Grid container item xs={12} sx={{ position: "relative" }}>
          <Loader
            height={{ height: window.innerHeight - 100, position: "absolute" }}
          />
        </Grid>
      ) : null}
      <Grid container item xs={12}>
        <Grid item xs={12} className={styles.headerView} mb={2}>
          <RestaurantHeader
            title={"AR Models"}
            onBackBtnClick={() => navigate("/restaurant/" + restaurantId)}
          />

          <Box
            className={styles.addBtnView}
            onClick={() =>
              navigate("/restaurant/" + restaurantId + "/select_menu")
            }
          >
            <Typography className={styles.addBtnText}>Add New Model</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} className={styles.arModelListMainView} p={2}>
          <Grid item xs={12} className={styles.arModelListView}>
            <SortableList
              allowDrag={true}
              className="menu-order-list-view"
              onSortEnd={(oldIndex, newIndex) => onSortEnd(oldIndex, newIndex)}
              draggedItemClassName="arModelListOrderDragged"
            >
              {menuList.map((x, i) => {
                return (
                  <SortableItem key={x._id}>
                    <Grid
                      item
                      key={i}
                      xs={12}
                      py={1}
                      pr={1.5}
                      mt={1}
                      className={styles.arModelMainView}
                    >
                      <SortableKnob>
                        <Box className="sortable-view" pl={2}>
                          <ExpandVerticalIcon height={30} width={30} />
                        </Box>
                      </SortableKnob>
                      <Box className={styles.arModelView}>
                        <Box className={styles.slNoMainView}>
                          <Box className={styles.slNoView} mx={1}>
                            <Typography className={styles.slNoText}>
                              {i + 1}
                            </Typography>
                          </Box>
                        </Box>
                        <Box style={{ width: "fit-content" }} pr={2}>
                          <Box className={styles.menuImgView}>
                            {x.images?.length ? (
                              <img
                                src={x.images[0]}
                                className={styles.imageStyle}
                              />
                            ) : (
                              <Box className={styles.menuImg}>
                                <MenuImgIcon width={"40%"} height={"40%"} />
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box className={styles.arModelDataView} pr={2}>
                          <Typography className={styles.menuName}>
                            {x.name}
                          </Typography>
                          <Typography className={styles.menuPrice}>
                            {CURRENCY_SYMBOL[x.price.currency]}
                            {x.price.value}
                          </Typography>
                          <Typography className={styles.menuDesc}>
                            {x.description}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        className={styles.viewModelBtn}
                        onClick={() =>
                          navigate(
                            "/restaurant/" + restaurantId + "/model/" + x._id
                          )
                        }
                      >
                        <Typography className={styles.viewModelBtnText}>
                          View Model
                        </Typography>
                      </Box>
                    </Grid>
                  </SortableItem>
                );
              })}
            </SortableList>
            {menuList.length == 0 && !isLoading ? (
              <Box className={styles.noModelTextView}>
                <Typography className={styles.noModelText}>
                  No Models
                </Typography>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default ArModels;
