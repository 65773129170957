import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputAdornment from "@mui/material/InputAdornment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ReactComponent as CalenderIcon } from "../assets/images/calender_gray.svg";

const SettingDatePicker = (props) => {
  const bgColor = props.backgroundColor;
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={props.value}
        format={props.inputFormat}
        onChange={props.onChange}
        slots={{
          openPickerIcon: () => {
            return (
              <InputAdornment position="start">
                {props?.leftView ?? <CalenderIcon width={20} height={20} />}
              </InputAdornment>
            );
          },
        }}
        slotProps={{
          inputAdornment: {
            position: "start",
          },
          actionBar: { actions: [] },
          textField: {
            variant: "filled",
            sx: {
              backgroundColor: `${bgColor}`,
              "& .MuiInputBase-root": {
                height: props?.height ?? 46,
                pl: 1,
                pr: 0,
                backgroundColor: "transparent",
                "&:before, :after, :hover:not(.Mui-disabled):before": {
                  borderBottom: 0,
                },
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "& .MuiFormLabel-root": {
                  display: "none",
                },
                "& .MuiInputAdornment-root": {
                  mt: "0 !important",
                  mr: 0,
                  "& .MuiButtonBase-root.MuiIconButton-root:hover": {
                    backgroundColor: "transparent",
                  },
                },
              },
            },
            inputProps: {
              readOnly: true,
              placeholder: "",
              value: props.value,
              sx: {
                borderBottom: "0px !important",
                color: props?.color ?? "#000",
                fontSize: 12,
                fontFamily: "GilroySemiBold",
                p: 0,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default SettingDatePicker;
