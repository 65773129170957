import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import SettingTextField from "./SettingTextField";
import UploadLogo from "./UploadLogo";

import styles from "./SocialMedia.module.css";

const SocialMedia = (props) => {
  let { data } = props;

  const onDelete = () => {
    props.onDelete(props.index);
  };

  const onUpdate = (field, value) => {
    props.onUpdate(field, value, props.index);
  };

  return (
    <Grid
      item
      container
      xs={12}
      justifyContent={"space-between"}
      sx={{ borderBottom: "2px solid #AAA" }}
      pb={2}
      mb={2}
    >
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={styles.textInputLabel}>Name</Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={data?.name}
            setValue={(val) => onUpdate("name", val)}
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={data?.error?.name}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={styles.textInputLabel}>Url</Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={data?.data?.url ?? null}
            setValue={(val) => onUpdate("url", val)}
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={data?.error?.url}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={styles.textInputLabel}>Action</Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={data?.action ?? null}
            setValue={(val) => onUpdate("action", val)}
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={data?.error?.action}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={styles.textInputLabel}>Icon width</Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={data?.data?.iconWidth ?? null}
            setValue={(val) =>
              onUpdate("iconWidth", val.replace(/[^0-9.]/g, ""))
            }
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={data?.error?.iconWidth}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mb={1}>
          <Typography className={styles.textInputLabel}>Icon height</Typography>
        </Box>
        <Box>
          <SettingTextField
            page={"SocialMedia"}
            editable={true}
            value={data?.data?.iconHeight ?? null}
            setValue={(val) =>
              onUpdate("iconHeight", val.replace(/[^0-9.]/g, ""))
            }
            placeholder={""}
            required={true}
            variant={"filled"}
            border={"1px solid #AAA"}
            error={data?.error?.iconHeight}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.8} mt={2}>
        <Box mr={2} mb={1}>
          <UploadLogo
            type={"socialMedia" + props.index}
            uploadFile={props.uploadLogoFile}
            imageLoading={props.imageLoading}
            logoImg={
              typeof data?.icon == "string" && data?.icon
                ? props.getImgDetail(data?.icon, "SocialMedia")
                : data?.icon
                ? data?.icon
                : ""
            }
            imageType={props.imageType}
            placeholder={"SOCIAL LOGO"}
            showViewUploadIcon={true}
            onRemove={() => onUpdate("icon", "")}
          />
          {data?.error?.icon ? (
            <Typography className={styles.errorText}>
              {data?.error?.icon}
            </Typography>
          ) : null}
        </Box>
      </Grid>
      <Grid container item xs={12} mt={2} justifyContent={"flex-end"}>
        <Button
          type="button"
          disabled={props.imageLoading}
          className={styles.deleteBtn}
          sx={{
            opacity: props.imageLoading ? 0.2 : 1,
          }}
          onClick={props.imageLoading ? null : onDelete}
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default SocialMedia;
