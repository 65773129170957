import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import moment from 'moment';

import {connect} from "react-redux";

import * as Api from "../../api";

import Container from "../../components/Container";
import EventList from "../../components/EventList";
import Page from "../../components/Page";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";

import '../../assets/css/custom.css';

import {THEME_COLOR2, THEME_COLOR4} from "../../constants";

import {ReactComponent as MenuBoxIcon} from '../../assets/images/menu_box.svg';
import {ReactComponent as EyeBlueIcon} from '../../assets/images/eye_blue.svg';
import {ReactComponent as ClockIcon} from '../../assets/images/clock.svg';
import {ReactComponent as CircleTickIcon} from '../../assets/images/circle_tick.svg';


const theme = createTheme({
  palette: {
    gray: {
      main: THEME_COLOR4,
    },
    red: {
      main: THEME_COLOR2,
    },
  },
});

const pageSize = 24;

const Events = (props) => {
  const [eventDate, setEventDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);
  const [eventList, setEventList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState('all');
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [msgAlert, setMsgAlert] = useState({open: false, message: '', msgType: ''});

  const navigate = useNavigate();

  useEffect(() => {
    getEventCategoryList();
  }, []);

  const getEventCategoryList = () => {
    setCardLoading(true);
    Api.eventCategoryList('/all').then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setCategoryList([{_id: 'all', name: 'All'}, ...data]);
        getEventList(1, 'all', '', '', '');
      } else {
        setCardLoading(false);
        setMsgAlert({open: true, message: response.msg, msgType: 'error'});
      }
    });
  };

  const getEventList = (pageNo, cat_id, search, start_date, end_date) => {
    let filter = `?pageSize=${pageSize}&pageNum=${pageNo}`;
    if (cat_id !== '' && cat_id !== 'all') {
      filter += `&filter__idCategory=${cat_id}`
    }
    if (search !== '') {
      filter += `&filter_name=${search}`
    }
    if (start_date !== '') {
      filter += `&filter_eventStartDate=${start_date}`
    }
    if (end_date !== '') {
      filter += `&filter_eventEndDate=${end_date}`
    }
    setCardLoading(true);
    Api.getEventList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setEventList(data);
        setPageNum(pageNo);
        setCategoryId(cat_id);
        setSearchText(search);
        setStartDate(start_date);
        setEndDate(end_date);
        setTotalPages(response.data.pages);
      } else {
        setCardLoading(false);
        setMsgAlert({open: true, message: response.msg, msgType: 'error'});
      }
      setCardLoading(false);
      setIsLoading(false);
    });
  };

  const onSelectPage = (value) => {
    getEventList(value, categoryId, searchText, startDate, endDate);
  };

  const onSearchEvent = (val) => {
    getEventList(1, categoryId, val, startDate, endDate);
  }

  const onEventClick = (type) => {
    setEventTitle(type);
    let start_date = '';
    let end_date = '';
    if (type == 'all') {
      getEventList(1, 'all', '', start_date, end_date);
    } else if (type == 'live') {
      let date1 = moment().valueOf();
      start_date = 'lte-' + date1;
      end_date = 'gte-' + date1;
      getEventList(1, categoryId, searchText, start_date, end_date);
    } else if (type == 'upcoming') {
      let date1 = moment().valueOf();
      start_date = 'gt-' + date1;
      getEventList(1, categoryId, searchText, start_date, '');
    } else if (type == 'completed') {
      let date1 = moment().valueOf();
      end_date = 'lt-' + date1;
      getEventList(1, categoryId, searchText, '', end_date);
    }
  }

  const onDateSelect = (val) => {
    setEventDate(val);
    let date1 = moment(val, 'dd.MM.yyyy').startOf('day').valueOf();
    let date2 = moment(val, 'dd.MM.yyyy').endOf('day').valueOf();
    let start_date = 'gte-' + date1;
    let end_date = 'lte-' + date2;
    getEventList(1, categoryId, searchText, start_date, end_date);
  }

  return (
    <Container page={"events"} searchVal={searchText} onSearch={onSearchEvent} searchLabel="Events">
      {isLoading ? <Loader height={{height: window.innerHeight - 150}}/> :
        <Grid container direction="row" item xs={12} md={12}>
          <Grid item xs={12} md={9}>
            <Box sx={styles.mainEventBox}>
              <Box mr={4} sx={styles.mainIconBox} onClick={() => onEventClick('all')}>
                <Box sx={styles.iconBox}>
                  <MenuBoxIcon height={22} width={22}/>
                </Box>
                <Typography sx={styles.iconText}>All</Typography>
              </Box>
              <Box mr={4} sx={styles.mainIconBox} onClick={() => onEventClick('live')}>
                <Box sx={styles.iconBox}>
                  <EyeBlueIcon height={22} width={22}/>
                </Box>
                <Typography sx={styles.iconText}>Live</Typography>
              </Box>
              <Box mr={4} sx={styles.mainIconBox} onClick={() => onEventClick('upcoming')}>
                <Box sx={styles.iconBox}>
                  <ClockIcon height={22} width={22}/>
                </Box>
                <Typography sx={styles.iconText}>Upcoming</Typography>
              </Box>
              <Box mr={4} sx={styles.mainIconBox} onClick={() => onEventClick('completed')}>
                <Box sx={styles.iconBox}>
                  <CircleTickIcon height={22} width={22}/>
                </Box>
                <Typography sx={styles.iconText}>Completed</Typography>
              </Box>
            </Box>
            <Box sx={styles.titleMainBox}>
              <Typography variant={'h6'} sx={styles.eventText}>{eventTitle} Events</Typography>
            </Box>
            {cardLoading ? <Loader height={{height: window.innerHeight - 325}}/> :
              <Grid container
                    direction="row"
                    item
                    mt={2}>
                {eventList.map((item, index) => {
                  return (
                    <EventList
                      key={index}
                      data={item}
                    />
                  )
                })}
              </Grid>}
            {totalPages > 0 ?
              <Page totalPages={totalPages}
                    pageNum={pageNum}
                    onSelectPage={onSelectPage}/> : null}
          </Grid>
          <Grid item xs={12} md={3} sx={{mb: {md: 0, xs: 2}}}>
            <Card sx={{
              p: 4,
              mt: {xs: 0, md: -3.2},
              flex: 1,
              marginLeft: {xs: 0, md: props.drawerOpen ? 0 : 3.1},
              width: {xs: '100%', md: window.innerWidth / 4.5},
              height: {xs: '100%', md: window.innerHeight - 60},
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              position: {xs: 'relative', md: 'fixed'}
            }}>
              <Box>
                <Box sx={styles.filterTitleBox}>
                  <Typography sx={styles.filterTitle}>Filters</Typography>
                  <Box sx={styles.notifMainBox}>
                    <Typography
                      sx={styles.notifTitle}>Notifications</Typography>
                    <Box sx={styles.notifCountBox}>
                      <Typography sx={styles.countText}>12</Typography>
                    </Box>
                  </Box>
                </Box>

                <Box mt={2}>
                  <Typography sx={styles.selectDateTitle}>Select Date</Typography>
                  <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        format="dd.MM.yyyy"
                        value={eventDate}
                        onChange={(val) => onDateSelect(val)}
                        slotProps={{
                          actionBar: { actions: [] },
                          textField: {
                            color: 'gray',
                            variant: 'standard',
                            inputProps: {
                              readOnly: true,
                              sx: {
                                height: 30,
                                fontSize: 13,
                                fontFamily: 'GilroySemiBold'
                              }
                            },
                            sx: {
                              svg: {color: '#000', fontSize: 18},
                              '& .MuiButtonBase-root': {
                                width: 35,
                                height: 35,
                              },
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </ThemeProvider>
                  <Box mt={2} sx={styles.borderLine}/>
                </Box>

                <Box mt={2}>
                  <Typography sx={styles.categoryTitle}>Category</Typography>
                  <Box sx={{overflowY: 'auto', maxHeight: {md: window.innerHeight - 350, xs: 300}}}>
                    <ThemeProvider theme={theme}>
                      <FormGroup>
                        {categoryList.map((item, index) => {
                          return (
                            <FormControlLabel
                              key={index}
                              control={<Checkbox size="small"
                                                 color="red"
                                                 checked={item._id === categoryId}
                                                 onChange={() => getEventList(1, item._id, searchText, startDate, endDate)}/>}
                              label={<Typography sx={styles.categoryItem}>{item.name}</Typography>}/>
                          )
                        })}
                      </FormGroup>
                    </ThemeProvider>
                  </Box>
                </Box>
              </Box>
              <Box sx={styles.createEventBox}
                   onClick={() => navigate('/add_event')}>
                <Typography sx={styles.createEventText}>Create Events</Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      }
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={() => setMsgAlert({open: false, message: '', msgType: 'error'})}/>
    </Container>
  )
};

const mapStateToProps = (state) => {
  return {
    drawerOpen: state.userData.drawerOpen,
  };
};

export default connect(mapStateToProps, null)(Events);


const styles = {
  mainEventBox: {
    display: 'flex',
    flexDirection: 'row',
    mt: 2
  },
  mainIconBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  iconBox: {
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
    width: 'fit-content',
    p: 1.7
  },
  iconText: {
    fontFamily: 'GilroyBold',
    fontSize: 13,
    textAlign: 'center',
    mt: 1
  },
  titleMainBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    mt: 4
  },
  eventText: {
    fontFamily: 'GilroyBold',
    textTransform: 'Capitalize',
  },
  filterTitleBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  filterTitle: {
    fontFamily: 'GilroyBold',
    fontSize: 15,
  },
  notifMainBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  notifTitle: {
    color: THEME_COLOR4,
    fontFamily: 'GilroyBold',
    fontSize: 15,
    mr: 1
  },
  notifCountBox: {
    backgroundColor: THEME_COLOR2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    width: 15,
    height: 15,
  },
  countText: {
    color: '#fff',
    fontFamily: 'GilroyMedium',
    fontSize: 9,
  },
  selectDateTitle: {
    fontFamily: 'GilroyBold',
    fontSize: 13,
  },
  borderLine: {
    border: '0.1px solid #EEE'
  },
  categoryTitle: {
    fontFamily: 'GilroyBold',
    fontSize: 13,
    mb: 1
  },
  categoryItem: {
    fontFamily: 'GilroyBold',
    fontSize: 12,
  },
  createEventBox: {
    backgroundColor: THEME_COLOR2,
    p: 1,
    mt: 2,
    cursor: 'pointer'
  },
  createEventText: {
    color: '#fff',
    fontFamily: 'GilroyMedium',
    textAlign: 'center',
    fontSize: 13,
  }
};
