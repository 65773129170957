import React, {useState} from 'react';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Grid';
import {createTheme, ThemeProvider} from "@mui/material/styles";

import './../assets/css/custom.css';

import {THEME_COLOR} from "../constants";

const theme = createTheme({
  palette: {
    red: {
      main: THEME_COLOR,
    },
  },
});

const Page = (props) => {

  const handleChange = (event, value) => {
    props.onSelectPage(value);
  };

  return (
    <Grid className="pagination-main-grid">
      <Grid className="pagination-grid">
        <ThemeProvider theme={theme}>
          <Pagination sx={{
            '& .MuiPaginationItem-root': {
              fontFamily: 'GilroySemiBold',
              fontSize: 13
            }
          }} count={props.totalPages} page={props.pageNum} onChange={handleChange} color="red"/>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}

export default Page;
