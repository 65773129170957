import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "./../assets/css/custom.css";

import { connect } from "react-redux";

const theme = (tagColor) =>
  createTheme({
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          tag: {
            backgroundColor: tagColor ? tagColor : "#EEE",
            "& .MuiChip-deleteIcon": {
              color: "#000000",
            },
          },
          listbox: {
            backgroundColor: "#FFFFFF",
          },
          option: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
            borderBottom: "1px solid #DDD",
            "&.Mui-focused": {
              backgroundColor: "#F3F3F3 !important",
            },
            '&[aria-selected="true"]': {
              backgroundColor: "#EEEEEE !important",
            },
          },
          clearIndicator: {
            color: "#000000",
          },
          popupIndicator: {
            color: "#000000",
          },
        },
      },
    },
  });

const SelectizeTextField = (props) => {
  let bgColor =
    props?.editable != false
      ? props?.backgroundColor
        ? props.backgroundColor
        : "#FFFFFF"
      : props?.disabledBg
      ? props.disabledBg
      : "#DDDDDD";
  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[
              {
                color: "#272D2F",
                fontSize: 15,
                fontFamily: "GilroySemiBold",
              },
              props?.labelStyle ? props.labelStyle : null,
            ]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          overflow: "hidden",
          pl: props?.leftView ? 1.5 : 0,
          pr: props?.rightView ? 1.5 : 0,
          backgroundColor: bgColor,
          border: props?.border ? props.border : "0px",
          borderRadius: props?.borderRadius ? props.borderRadius : "12px",
          width: props?.width ? props.width : "100%",
        }}
      >
        <ThemeProvider theme={theme(props?.tagColor ? props.tagColor : null)}>
          <Autocomplete
            multiple={
              typeof props?.multiple !== "undefined" ? props?.multiple : true
            }
            size="small"
            options={props.options}
            onChange={(e, val) => props.onChange(val)}
            value={props.value}
            sx={{
              "&.MuiAutocomplete-root": {
                width: "100%",
              },
            }}
            ListboxProps={{
              sx: {
                fontSize: 12,
                fontFamily: "GilroyMedium",
              },
            }}
            ChipProps={{
              sx: {
                "& .MuiChip-label": {
                  fontSize: 12,
                  fontFamily: "GilroyMedium",
                  color: "#000000",
                },
              },
            }}
            disableClearable={props?.disableClearable == true}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={props?.variant ? props?.variant : "standard"}
                label={""}
                placeholder={props?.placeholder ? props?.placeholder : ""}
                sx={{
                  "& .MuiInputBase-input": {
                    fontSize: "13px !important",
                    fontFamily: "GilroySemiBold",
                    color: props?.placeholderColor
                      ? props.placeholderColor
                      : "#333333",
                  },
                  "& .MuiInputBase-root": {
                    backgroundColor: bgColor,
                    paddingBottom: "6px !important",
                    "&:hover": {
                      backgroundColor: bgColor,
                    },
                    "&.Mui-focused": {
                      backgroundColor: bgColor,
                    },
                    "&.Mui-disabled": {
                      backgroundColor: bgColor,
                    },
                    "&:before, :after, :hover:not(.Mui-disabled):before": {
                      borderBottom: props?.borderBottom
                        ? props.borderBottom
                        : 0,
                    },
                    "& .MuiFilledInput-input": {
                      width: "100% !important",
                    },
                    "& .MuiIconButton-root": {
                      paddingTop: "7px !important",
                    },
                  },
                }}
              />
            )}
          />
        </ThemeProvider>
      </Box>
      <Box>
        {props?.error ? (
          <Typography
            sx={{
              color: "#CD0000",
              fontSize: 10,
              fontFamily: "GilroyMedium",
            }}
          >
            {props.error}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
  };
};

export default connect(mapStateToProps, null)(SelectizeTextField);
