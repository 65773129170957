import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import userDataReducer from "./userDataReducer";

const persistConfig = {
  timeout: 0,
  key: "root",
  storage: storageSession,
  blacklist: ["userData"],
};

const userDataPersistConfig = {
  key: "userData",
  storage: storageSession,
  blacklist: ["drawerOpen"],
};

const rootReducer = combineReducers({
  userData: persistReducer(userDataPersistConfig, userDataReducer),
});

export default persistReducer(persistConfig, rootReducer);
