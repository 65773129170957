import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import moment from "moment";

import {CURRENCY_SYMBOL, THEME_COLOR, THEME_COLOR2, THEME_COLOR3} from "../constants";

import ProPic from './../assets/images/pro_pic.png';
import {ReactComponent as LocationIcon} from './../assets/images/location.svg';
import {ReactComponent as EyeWhiteIcon} from './../assets/images/eye_white.svg';

const symbol = CURRENCY_SYMBOL;

const EventList = (props) => {
  let data = props.data;

  let location = Object.keys(data.location).filter(k => (k != '_id' && k != 'coords' && data.location[k] != '')).map(function (k) {
    return data.location[k]
  }).join(", ");

  return (
    <Grid item xs={12} md={4}>
      <Card sx={styles.mainCard}>

        <Box sx={{position: 'relative'}}>
          <Box sx={styles.viewBox}>
            <EyeWhiteIcon height={20} width={20}/>
          </Box>

          <CardMedia
            component="img"
            sx={{height: {md: window.innerHeight / 3.5, xs: window.innerHeight / 4}}}
            image={data.images[0]}
            style={{borderRadius: 13}}
          />

          <Box sx={styles.dateBox}>
            <Typography sx={styles.dateText}>{moment(data.eventStartDate).format('DD MMM Y')}</Typography>
          </Box>
          <Box sx={styles.priceBox}>
            <Typography sx={styles.priceText}>
              {data.prize.length > 0 ?
                ((typeof symbol[data.prize[0]['currency']] == 'undefined' ?  '' : symbol[data.prize[0]['currency']])
                  + ' ' + data.prize[0]['value']) : '$ ' + 0}
            </Typography>
          </Box>
        </Box>

        <CardActions disableSpacing>
          <CardContent sx={styles.cardContent}>
            <Typography sx={styles.nameText}>
              {data.name}
            </Typography>
            <Typography sx={styles.byTeamText}>
              By Team here
            </Typography>
            <Box sx={styles.addressBox}>
              {location ?
                <>
                  <Grid>
                    <Box sx={styles.locationIcon}>
                      <LocationIcon height={18} width={18}/>
                    </Box>
                  </Grid>
                  <Typography sx={styles.addressText}>
                    {location}
                  </Typography>
                </> : null}
            </Box>
            <Typography sx={styles.descText}>
              {data.description}
            </Typography>
          </CardContent>
        </CardActions>
        <Box mt={2}/>
      </Card>
      <Box sx={{position: 'relative'}} mt={5}>
        <Card elevation={3} sx={styles.othersCard}>
          <img src={ProPic}
               style={{height: 35, width: 35, marginRight: -10}}/>
          <img src={ProPic}
               style={{height: 35, width: 35, marginRight: -10}}/>
          <img src={ProPic}
               style={{height: 35, width: 35}}/>
          <Typography sx={styles.othersText}>+50 others are going</Typography>
        </Card>
      </Box>
    </Grid>
  );
}

export default EventList;

const styles = {
  mainCard: {
    width: {md: '95%', xs: '100%'},
    marginBottom: 2,
    borderRadius: 3,
    px: 1.5,
    pt: 1.5,
    minHeight: '91.5%',
  },
  viewBox: {
    cursor: 'pointer',
    backgroundColor: '#7033ff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 10,
    top: 0,
    left: 0,
    py: 1.3,
    px: 1.5,
  },
  dateBox: {
    backgroundColor: THEME_COLOR,
    position: 'absolute',
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    bottom: 0,
    left: 0,
    pt: 1,
    pb: 0.4,
    px: 2
  },
  dateText: {
    fontFamily: 'GilroyBold',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  priceBox: {
    backgroundColor: THEME_COLOR2,
    position: 'absolute',
    borderRadius: 5,
    bottom: -10,
    right: 10,
    px: 2.5,
    py: 0.4
  },
  priceText: {
    fontFamily: 'GilroyBold',
    fontSize: 13,
    color: '#fff'
  },
  cardContent: {
    width: '100%',
    padding: 0,
    mt: 1
  },
  nameText: {
    fontFamily: 'GilroyBold',
    fontSize: 14,
  },
  byTeamText: {
    color: THEME_COLOR3,
    fontFamily: 'GilroyMedium',
    fontSize: 12,
    lineHeight: 1
  },
  addressBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    my: 1,
  },
  locationIcon: {
    backgroundColor: '#EEE',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 48,
    height: 48,
    borderRadius: 24,
  },
  addressText: {
    color: THEME_COLOR3,
    fontFamily: 'GilroyRegular',
    fontSize: 10,
    ml: 1
  },
  descText: {
    fontFamily: 'GilroyRegular',
    fontSize: 11,
  },
  othersCard: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 25,
    width: {md: '95%', xs: '100%'},
    bottom: 20,
    p: 1
  },
  othersText: {
    color: THEME_COLOR3,
    fontFamily: 'GilroyRegular',
    fontSize: 11,
    ml: 1
  }
};
