import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import axios from 'axios';
import {renderTimeViewClock} from "@mui/x-date-pickers/timeViewRenderers";

import AddEventImagesUpload from "./../components/AddEventImagesUpload";
import AlertMsg from "./AlertMsg";

import {API_KEY, CURRENCY_LIST, THEME_COLOR, THEME_COLOR2, THEME_COLOR3, THEME_COLOR4} from "../constants";

import './../assets/css/custom.css';

import {ReactComponent as RedCloseIcon} from "../assets/images/red_close.svg";

const theme = createTheme({
  palette: {
    gray: {
      main: THEME_COLOR4,
    },
    yellow: {
      main: THEME_COLOR,
    },
  },
});

const textInputCss = {
  "& .MuiInput-underline:before": {
    borderBottom: `1.5px solid ${THEME_COLOR4}`
  },
  "& .MuiInput-underline:after": {
    borderBottom: `1.5px solid ${THEME_COLOR4}`
  },
  "& .MuiInput-underline:hover": {
    borderBottom: `0.5px solid ${THEME_COLOR4}`
  },
};

const symbol = CURRENCY_LIST;

const AddEvent = (props) => {
  const [eventName, setEventName] = useState('');
  const [locLat, setLocLat] = useState('');
  const [locLng, setLocLng] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [priceUnit, setPriceUnit] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [address, setAddress] = useState({
    firstLine: "",
    secondLine: "",
    city: "",
    countryCode: "",
    postCode: "",
  });
  const [fromDate, setFromDate] = useState(new Date(Date.now() + (5 * 60 * 1000)));
  const [toDate, setToDate] = useState(new Date(Date.now() + (5 * 60 * 1000)));
  const [restaurantId, setRestaurantId] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [msgAlert, setMsgAlert] = useState({open: false, message: '', msgType: 'error'});

  useEffect(() => {
    if (locLat != '' && locLng != '') {
      getLocationAddress();
    }
  }, [locLat, locLng]);

  useEffect(() => {
    let upload_list = imageList.filter(x => x.upload == false);
    if (upload_list.length > 0) {
      setImageLoading(true);
    } else {
      setImageLoading(false);
    }
  }, [imageList]);

  const onSelectCategory = (data) => {
    setCategoryId(data._id);
  };

  const onsubmitEvent = (e) => {
    e.preventDefault();

    if (/[^0-9.]/g.test(price)) {
      setMsgAlert({open: true, message: 'Invalid Price', msgType: 'error'});
      return;
    }

    if (imageList.length === 0) {
      setMsgAlert({open: true, message: 'Image is required', msgType: 'error'});
      return;
    }

    if (categoryId === '') {
      setMsgAlert({open: true, message: 'Category is required', msgType: 'error'});
      return;
    }

    if (restaurantId.length === 0) {
      setMsgAlert({open: true, message: 'Select Restaurants', msgType: 'error'});
      return;
    }

    if (floatNumCheck(locLat) == '' || floatNumCheck(locLat) == '+' || floatNumCheck(locLat) == '-') {
      setMsgAlert({open: true, message: 'Latitude is required', msgType: 'error'});
      return;
    }

    if (floatNumCheck(locLng) == '' || floatNumCheck(locLng) == '+' || floatNumCheck(locLng) == '-') {
      setMsgAlert({open: true, message: 'Longitude is required', msgType: 'error'});
      return;
    }

    // if (address.firstLine.trim() == '' &&
    //   address.secondLine.trim() == '' &&
    //   address.city.trim() == '' &&
    //   address.countryCode.trim() == '' &&
    //   address.postCode.trim() == '') {
    //   setMsgAlert({open: true, message: 'Select Address', msgType: 'error'});
    //   return;
    // }

    if (toDate < fromDate) {
      setMsgAlert({open: true, message: 'End date should be greater than start date', msgType: 'error'});
      return;
    }

    let data = {
      name: eventName,
      category: categoryId,
      restaurants: restaurantId.map(x => {
        return x._id
      }),
      description: description,
      location: {
        firstLine: address.firstLine,
        secondLine: address.secondLine,
        city: address.city,
        countryCode: address.countryCode,
        postCode: address.postCode,
        coords: [locLat, locLng]
      },
      images: imageList.map(x => {
        return x.name
      }),
      price: parseFloat(price).toFixed(2),
      priceUnit: priceUnit,
      startDate: fromDate,
      endDate: toDate,
    }
    props.onSubmit(data);
  }

  const uploadFile = (list, loading, type) => {
    if (type == 'loading') {
      setImageList(prev => [...prev, list]);
    } else if (type == 'success') {
      setImageList(prev => prev.map((el) => el.id == list.id ? {...el, ...list} : el));
    } else if (type == 'error') {
      setImageList(prev => prev.filter(x => x.id !== list.id))
    }
  };

  const getLocationAddress = () => {
    axios.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + locLat + ',' + locLng + '&key=' + API_KEY).then(res => {
      let response1 = res.data;
      if (response1.results.length) {
        let first_line = '';
        let second_line = '';
        let city = '';
        let country_code = '';
        let pincode = '';

        let locationResponse = response1.results[0].address_components;

        response1.results.map(function (item) {
          if (item.types[0] === 'administrative_area_level_2') {
            second_line = item.address_components?.[0]?.long_name + ', ';
          } else if (item.types[0] === 'administrative_area_level_1') {
            second_line += item.address_components?.[0]?.long_name;
          } else if (item.types[0] === 'country') {
            country_code = item.address_components?.[0]?.short_name;
          } else if (item.types[0] === 'postal_code') {
            pincode = item.address_components?.[0]?.long_name;
          } else if (item.types[0] === 'locality') {
            city = item.address_components?.[0]?.long_name;
          } else if (item.types[0] === 'street_address') {
            let locationArray = item.formatted_address.split(',');
            first_line =
              locationArray?.[0] +
              ', ' +
              locationArray?.[1] +
              ', ' +
              locationArray?.[2];
          } else {
            if (city == '') {
              city = item.address_components?.[0]?.long_name;
            }
          }
        });

        if (first_line == '') {
          first_line = locationResponse?.[0]?.long_name;
          if (locationResponse.length >= 2) {
            first_line += locationResponse?.[1]?.long_name;
          }
          if (locationResponse.length >= 3) {
            first_line += locationResponse?.[2]?.long_name;
          }
        }

        setAddress({
          firstLine: first_line,
          secondLine: second_line,
          city: city,
          countryCode: country_code,
          postCode: pincode,
        });
      }
    }).catch((e) => {
      console.log(e)
      if (e?.data?.error_message) {
        setMsgAlert({open: true, message: e.data.error_message, msgType: 'error'});
      } else {
        setMsgAlert({open: true, message: "Invalid 'latlng' parameter", msgType: 'error'});
      }
    });
  };

  const floatNumCheck = (val) => {
    let sign = '';
    val = val.toString();
    if (val.length >= 1) {
      if (val[0] == '+' || val[0] == '-') {
        sign = val[0];
      }
    }
    val = val.replace(/[^0-9\.]/g, '');
    if (val.split('.').length > 2) {
      val = val.replace(/\.+$/, '');
    }
    return (sign + val);
  };

  return (
    <Grid component="form"
          autoComplete="off"
          onSubmit={onsubmitEvent}>
      <Grid mb={5}>
        <AddEventImagesUpload
          imageList={imageList}
          imageLoading={imageLoading}
          onSetAlertMsg={setMsgAlert}
          uploadFile={uploadFile}/>
      </Grid>

      <Grid
        container
        direction="row"
        item xs={12}
        justifyContent="space-between">

        <Grid item xs={12} md={5.5} mt={2}>
          <Grid>
            <ThemeProvider theme={theme}>
              <TextField
                focused
                fullWidth
                required
                value={eventName}
                onChange={e => setEventName(e.target.value)}
                id="events"
                label="Name of events"
                color="gray"
                variant="standard"
                sx={textInputCss}
                InputLabelProps={{
                  style: {color: THEME_COLOR3},
                  sx: {...styles.inputLabelText}
                }}
                inputProps={{
                  autoComplete: 'none',
                  sx: {...styles.textInputValue}
                }}
              />
            </ThemeProvider>
          </Grid>
          <Grid sx={{mt: {md: 6, xs: 5}}}>
            <ThemeProvider theme={theme}>
              <TextField
                focused
                fullWidth
                required
                value={description}
                onChange={e => setDescription(e.target.value)}
                id="description"
                label="Description"
                color="gray"
                variant="standard"
                sx={textInputCss}
                InputLabelProps={{
                  style: {color: THEME_COLOR3},
                  sx: {...styles.inputLabelText}
                }}
                inputProps={{
                  autoComplete: 'none',
                  sx: {...styles.textInputValue}
                }}
              />
            </ThemeProvider>
          </Grid>
          <Grid mt={5}>
            <label style={styles.labelText}>Rewards</label>
            <Grid container direction="row">
              <Grid item xs={2} style={{width: '100%'}}>
                <FormControl style={{width: '100%'}}>
                  <ThemeProvider
                    theme={theme}>
                    <Select variant="standard"
                            color="gray"
                            required
                            sx={{fontSize: 13, fontFamily: 'GilroyBold', ...textInputCss}}
                            value={priceUnit}
                            onChange={(event) => setPriceUnit(event.target.value)}>
                      {symbol.map((item, index) => (
                        <MenuItem
                          sx={{fontSize: 13, fontFamily: 'GilroyBold'}}
                          key={index}
                          value={item.currency}>
                          {item.symbol}
                        </MenuItem>
                      ))}
                    </Select>
                  </ThemeProvider>
                </FormControl>
              </Grid>
              <Grid item xs={10}>
                <ThemeProvider theme={theme}>
                  <TextField
                    focused
                    fullWidth
                    required
                    color="gray"
                    id="price"
                    variant="standard"
                    sx={textInputCss}
                    value={price}
                    onChange={e => setPrice(e.target.value.replace(/[^0-9.]/g, ''))}
                    InputProps={{
                      autoComplete: 'none',
                      sx: {fontSize: 13, fontFamily: 'GilroyBold'},
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box className="menu-price-box"
                               style={{border: '0.5px solid ' + THEME_COLOR4}}/>
                        </InputAdornment>
                      )
                    }}/>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid mt={5}>
            <label style={styles.labelText}>List of Restaurants</label>
            <FormControl sx={{m: 0, width: '100%'}}>
              <ThemeProvider theme={theme}>
                <Autocomplete
                  multiple
                  id="events"
                  label="List of Restaurants"
                  color="gray"
                  options={props.restaurantList}
                  clearIcon={false}
                  sx={textInputCss}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        sx={{
                          border: '1px solid #000',
                          borderRadius: 15,
                          height: 20,
                          fontSize: 11,
                          fontFamily: 'GilroyBold',
                          backgroundColor: '#fff'
                        }}
                        size="small"
                        variant="outlined"
                        key={option._id}
                        label={option.name}
                        deleteIcon={<RedCloseIcon height={13} width={13}/>}
                        {...getTagProps({index})}
                      />
                    ))
                  }
                  onChange={(e, val) => setRestaurantId(val)}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      focused
                      color="gray"
                    />
                  )}
                />
              </ThemeProvider>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} md={5.5} sx={{mt: {md: 2, xs: 5}}}>
          <ThemeProvider theme={theme}>
            <Grid container item xs={12} md={12} direction="row">
              <Grid item xs={12} md={6} sx={{paddingRight: 5, marginTop: {xs: 2, md: 0}}}>
                <TextField
                  focused
                  fullWidth
                  required
                  id="events"
                  label="Latitude"
                  color="gray"
                  variant="standard"
                  value={locLat}
                  onChange={e => setLocLat(floatNumCheck(e.target.value))}
                  sx={textInputCss}
                  InputLabelProps={{
                    style: {color: THEME_COLOR3},
                    sx: {...styles.inputLabelText}
                  }}
                  inputProps={{
                    autoComplete: 'none',
                    sx: {...styles.textInputValue}
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} sx={{paddingRight: 5, marginTop: {xs: 2, md: 0}}}>
                <TextField
                  focused
                  fullWidth
                  required
                  id="events"
                  label="Longitude"
                  color="gray"
                  variant="standard"
                  value={locLng}
                  onChange={e => setLocLng(floatNumCheck(e.target.value))}
                  sx={textInputCss}
                  InputLabelProps={{
                    style: {color: THEME_COLOR3},
                    sx: {...styles.inputLabelText}
                  }}
                  inputProps={{
                    autoComplete: 'none',
                    sx: {...styles.textInputValue}
                  }}
                />
              </Grid>
            </Grid>
          </ThemeProvider>

          <Grid sx={{mt: {md: 2, xs: 5}}}>
            <label style={styles.labelText}>Event Date</label>
            <ThemeProvider theme={theme}>
              <Grid container item xs={12} md={12} direction="row">
                <Grid item xs={12} md={6} sx={{paddingRight: 5, marginTop: {xs: 2, md: 0}}}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="From"
                      format="dd.MM.yyyy hh:mm aa"
                      minDateTime={new Date()}
                      value={fromDate}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock
                      }}
                      onChange={(val) => setFromDate(val)}
                      slotProps={{
                        actionBar: { actions: [] },
                        textField: {
                          color: 'gray',
                          variant: 'standard',
                          InputLabelProps: {
                            style: {fontFamily: 'GilroyRegular'},
                          },
                          inputProps: {
                            readOnly: true,
                            sx: {
                              height: 30,
                              fontSize: 13,
                              fontFamily: 'GilroySemiBold'
                            }
                          },
                          sx: {
                            svg: {color: '#000', fontSize: 18},
                            '& .MuiButtonBase-root': {
                              width: 35,
                              height: 35,
                            },
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={6} sx={{paddingRight: 5, marginTop: {xs: 2, md: 0}}}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="To"
                      value={toDate}
                      minDateTime={fromDate}
                      format="dd.MM.yyyy hh:mm aa"
                      onChange={(val) => setToDate(val)}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock
                      }}
                      slotProps={{
                        actionBar: { actions: [] },
                        textField: {
                          color: 'gray',
                          variant: 'standard',
                          InputLabelProps: {
                            style: {fontFamily: 'GilroyRegular'},
                          },
                          inputProps: {
                            readOnly: true,
                            sx: {
                              height: 30,
                              fontSize: 13,
                              fontFamily: 'GilroySemiBold'
                            }
                          },
                          sx: {
                            svg: {color: '#000', fontSize: 18},
                            '& .MuiButtonBase-root': {
                              width: 35,
                              height: 35,
                            },
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </ThemeProvider>
          </Grid>

          <Grid mt={5}>
            <label style={styles.labelText}>Category</label>
            <Grid container direction="row" sx={{mt: {md: 1, xs: 0}}}>
              <ThemeProvider theme={theme}>
                {props.categoryList.map((item, index) => {
                  return (
                    <Box key={index} pr={1} onClick={() => onSelectCategory(item)}>
                      <Box
                        sx={[styles.categoryListBox, (categoryId === item._id) ? styles.selectedCat : styles.nonSelectedCat]}
                        px={2.2}
                        py={0.5}>
                        <Typography
                          sx={[styles.categoryList, (categoryId === item._id) ? styles.selected : styles.nonSelected]}>
                          {item.name}
                        </Typography>
                      </Box>
                    </Box>
                  )
                })}
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Grid container direction="row" justifyContent="center" alignItems="center" mt={7} mb={5}>
        <Button disabled={(props.isLoading || imageLoading)} type={'submit'} sx={styles.btnText}>
          {(props.isLoading || imageLoading) ? 'Please wait' : 'Submit'}
        </Button>
      </Grid>

      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={() => setMsgAlert({open: false, message: '', msgType: 'error'})}/>
    </Grid>
  );
};

export default AddEvent;

const styles = {
  labelText: {
    color: THEME_COLOR3,
    fontFamily: 'GilroyRegular',
    fontSize: 12
  },
  inputLabelText: {
    fontFamily: 'GilroyRegular'
  },
  textInputValue: {
    fontSize: 14,
    fontFamily: 'GilroySemiBold'
  },
  categoryListBox: {
    border: '1px solid ' + THEME_COLOR4,
    borderRadius: 10,
    cursor: 'pointer',
    mt: {xs: 1, md: 0}
  },
  categoryList: {
    fontFamily: 'GilroyMedium',
    fontSize: 13
  },
  selectedCat: {
    borderColor: THEME_COLOR2,
  },
  nonSelectedCat: {
    borderColor: THEME_COLOR4,
  },
  selected: {
    color: THEME_COLOR2,
  },
  nonSelected: {
    color: THEME_COLOR4,
  },
  btnText: {
    backgroundColor: THEME_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    textTransform: 'capitalize',
    color: '#000',
    fontFamily: 'GilroyBold',
    fontSize: 13,
    borderRadius: 1,
    width: 100,
    height: 35,
    '&:hover': {
      backgroundColor: THEME_COLOR + ' !important',
    }
  }
}
