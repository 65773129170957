import React from 'react';
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";

import {THEME_COLOR} from "../constants";

const RestaurantBtn = (props) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      mt={7}
      mb={5}>
      {(props.activeTab === 1 || props.activeTab === 2) ?
        <Button disabled={props.loading} type={'button'} sx={[styles.btnText, {mr: '5px !important'}]}
                onClick={props.previousBtnClick}>Previous</Button> : null}
      {(props.activeTab === 0 || props.activeTab === 1) ?
        <Button disabled={props.loading} type={'submit'} sx={styles.btnText}>Next</Button> : null}
      {(props.activeTab === 2) ?
        <Button disabled={props.loading} type={'button'} sx={styles.btnText} onClick={props.onSaveBtnClick}>
          {props.loading ? 'Please Wait' : 'Save'}
        </Button> : null}
      {(props.activeTab === 4) ?
        <Button disabled={props.loading} type={'submit'}
                sx={{...styles.btnText, marginRight: 3}} id="addNextBtn">
          Add Next
        </Button> : null}
      {(props.activeTab === 3 || (props.activeTab === 4 && props.showSaveBtn)) ?
        <Button disabled={props.loading} type={'submit'} sx={styles.btnText} id="submitBtn">
          {props.loading ? 'Please Wait' : 'Save'}
        </Button> : null}
    </Grid>
  );
}

export default RestaurantBtn;

const styles = {
  btnText: {
    backgroundColor: THEME_COLOR,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    textTransform: 'capitalize',
    color: '#000',
    fontFamily: 'GilroyBold',
    fontSize: 13,
    borderRadius: 1,
    width: 100,
    height: 35,
    '&:hover': {
      backgroundColor: THEME_COLOR + ' !important',
    },
  }
}
