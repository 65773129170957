import * as React from 'react';
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import {ReactComponent as DeleteIcon} from "../../assets/images/delete.svg";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {forwardRef} from "react";
import Slide from "@mui/material/Slide";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmAlert = (props) => {

  return (
    <Dialog
      open={props.confirmDelete}
      TransitionComponent={Transition}
      keepMounted
      // onClose={onCloseConfirmDelete}
      aria-describedby="alert-dialog-slide-description"
      sx={styles.mainDialog}>
      <Grid p={4}>
        <Grid container justifyContent="center" alignItems="center">
          <DeleteIcon height={50} width={50}/>
        </Grid>
        <DialogTitle sx={styles.confirmTitleText}>
          {props.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" sx={styles.confirmContentText}>
            {props.content}
            <br/>
            {props?.name}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={styles.confirmBtnDialog}>
          <Button sx={[styles.btnText, styles.noBtnText]}
                  onClick={props.onCloseConfirmDelete}>
            {props.firstBtn}
          </Button>
          <Button sx={[styles.btnText, styles.yesBtnText]}
                  disabled={props.btnLoading}
                  onClick={props.onConfirmDelete}>
            {props.secondBtn}
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default ConfirmAlert;

const styles = {
  mainDialog: {
    '& .MuiDialog-paper': {
      borderRadius: 3,
    },
  },
  confirmTitleText: {
    fontFamily: 'GilroyBold',
    textAlign: 'center',
  },
  confirmContentText: {
    fontFamily: 'GilroyMedium',
    textAlign: 'center'
  },
  confirmBtnDialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  btnText: {
    textTransform: 'capitalize',
    fontFamily: "GilroySemiBold",
  },
  noBtnText: {
    color: '#000',
    '&:hover': {
      backgroundColor: '#fff !important',
    },
  },
  yesBtnText: {
    backgroundColor: '#FE724C',
    color: '#fff',
    borderRadius: 2,
    px: 2,
    '&:hover': {
      backgroundColor: '#FE724C !important',
    },
  }
}
