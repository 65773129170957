import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";

import UploadFile from "../UploadFile";
import AlertMsg from "../AlertMsg";

import * as Api from "../../api";

import "../../assets/css/custom.css";

import {
  BTN_COLOR,
  BTN_TEXT_COLOR,
  THEME_COLOR,
  THEME_COLOR3,
  THEME_COLOR4,
} from "../../constants";

const theme = createTheme({
  palette: {
    gray: {
      main: THEME_COLOR4,
    },
  },
});

const textInputCss = {
  "& .MuiInput-underline:before": {
    borderBottom: `1.5px solid ${THEME_COLOR4}`,
  },
  "& .MuiInput-underline:after": {
    borderBottom: `1.5px solid ${THEME_COLOR4}`,
  },
  "& .MuiInput-underline:hover:before": {
    borderBottom: 0,
  },
};

const AddSettingCategory = (props) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageLoading, setImageLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    if (props.catData != null) {
      let data = props.catData;
      setName(data.name);
      setDescription(data.description);
      setImageList(data.images);
    } else {
      setName("");
      setDescription("");
      setImageList([]);
      setImageLoading(false);
      setBtnDisabled(false);
    }
  }, [props]);

  useEffect(() => {
    let upload_list = imageList.filter((x) => x.upload == false);
    if (upload_list.length > 0) {
      setImageLoading(true);
    } else {
      setImageLoading(false);
    }
  }, [imageList]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (imageList.length === 0) {
      setMsgAlert({
        open: true,
        message: "Image is required",
        msgType: "error",
      });
      return;
    }

    let data = {
      catName: name,
      catDescription: description,
      catImageList: imageList,
    };

    let type = "add";
    if (props.catData != null) {
      type = "edit";
      data = {
        ...props.catData,
        ...data,
      };
    }

    onSubmitCategory(data, type);
  };

  const onSubmitCategory = (data, type) => {
    let catImageData = [];
    let imgUrl = [];
    data.catImageList.forEach((item, index) => {
      catImageData.push(item.name);
      imgUrl.push(item.image);
    });

    let data1 = {
      name: data.catName,
      description: data.catDescription,
      _idRestaurant: props.restaurantId,
      images: catImageData,
    };
    let datas = JSON.stringify(data1);

    setBtnDisabled(true);
    if (type == "add") {
      Api.addMenuCategory(datas).then((response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: "Added Successfully",
            msgType: "success",
          });
          props.onSubmit();
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setBtnDisabled(false);
      });
    } else {
      Api.updateMenuCategory(datas, data._id).then((response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: "Updated Successfully",
            msgType: "success",
          });
          props.onSubmit();
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setBtnDisabled(false);
      });
    }
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const onRemoveImage = (data) => {
    setImageList([]);
    // let menuImage = [...imageList];
    // let imageIndex = menuImage.findIndex((item) => item.id == data.id);
    // if (imageIndex >= 0) {
    //   menuImage.splice(imageIndex, 1);
    //   setImageList(menuImage);
    // }
  };

  const uploadFile = (data, file) => {
    setImageList((prev) => [...prev, data]);
    setImageLoading(true);

    let formData = new FormData();
    formData.append("image", file);

    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        data["name"] = response.data.fileId;
        data["image"] = response.data.location;
        data["upload"] = true;
        setImageList((prev) =>
          prev.map((el) => (el.id == data.id ? { ...el, ...data } : el))
        );
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const uploadError = (err) => {
    setMsgAlert({ open: true, message: err, msgType: "error" });
  };

  return (
    <Grid
      component="form"
      onSubmit={onSubmit}
      container
      direction="column"
      sx={styles.mainGrid}
    >
      <Grid
        sx={{
          flex: 1,
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid>
          <Grid container direction="row" item xs={12}>
            <UploadFile
              page={"addResItemImg"}
              uploadText={"Drag and Drop Your Images Here"}
              maxFiles={1}
              imageLoading={imageLoading || btnDisabled}
              imageList={imageList}
              imageLength={imageList.length}
              onRemoveImage={onRemoveImage}
              uploadError={uploadError}
              uploadFile={uploadFile}
            />
          </Grid>

          <Grid sx={{ mt: 3 }}>
            <Grid item sx={{ mb: 3 }}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  style={{ width: "100%" }}
                  value={name}
                  color="gray"
                  id="categoryTitle"
                  label="Category Title"
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    style: { color: THEME_COLOR3 },
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) => setName(e.target.value)}
                />
              </ThemeProvider>
            </Grid>
            <Grid item sx={{ mb: 3 }}>
              <ThemeProvider theme={theme}>
                <TextField
                  required
                  focused
                  style={{ width: "100%" }}
                  value={description}
                  color="gray"
                  id="description"
                  label="Description"
                  variant="standard"
                  sx={textInputCss}
                  InputLabelProps={{
                    style: { color: THEME_COLOR3 },
                    sx: { ...styles.inputLabelText },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { ...styles.inputText },
                  }}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="space-between">
        <Button
          type="button"
          disabled={imageLoading || btnDisabled}
          sx={styles.cancelBtn}
          onClick={props.onCancel}
        >
          Cancel
        </Button>
        <Button
          disabled={imageLoading || btnDisabled}
          type="submit"
          sx={styles.saveBtn}
        >
          {imageLoading || btnDisabled
            ? "Please Wait"
            : props.catData == null
            ? "Add Category"
            : "Update Category"}
        </Button>
      </Grid>
      <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg} />
    </Grid>
  );
};

export default AddSettingCategory;

const styles = {
  mainGrid: {
    width: "100%",
    minHeight: "100%",
    py: 1,
    px: 5,
  },
  inputLabelText: {
    fontFamily: "GilroyRegular",
  },
  inputText: {
    fontSize: 13,
    fontFamily: "GilroySemiBold",
    my: 0.8,
  },
  cancelBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    backgroundColor: "#FFF",
    color: "#000",
    fontFamily: "GilroyBold",
    fontSize: 14,
    borderRadius: 1,
    border: "2px solid #000000",
    width: "49%",
    height: 44,
    "&:hover": {
      backgroundColor: "#FFF !important",
    },
  },
  saveBtn: {
    backgroundColor: BTN_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: BTN_TEXT_COLOR,
    fontFamily: "GilroyBold",
    fontSize: 14,
    borderRadius: 1,
    width: "49%",
    height: 44,
    "&:hover": {
      backgroundColor: BTN_COLOR + " !important",
    },
  },
};
