import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import * as Api from "../../api";

import Container from "../../components/Container";
import UserList from "../../components/UserList";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import AlertMsg from "../../components/AlertMsg";

import "../../assets/css/custom.css";

import { THEME_COLOR3, THEME_COLOR4 } from "../../constants";

const user_list = [
  {
    id: 1,
    name: "User Name",
    desc: "Location-kochi,6th Floor Imperial, Trade Center",
    mobile: "+91 9214555555",
  },
  {
    id: 2,
    name: "User Name",
    desc: "Location-kochi,6th Floor Imperial, Trade Center",
    mobile: "+91 9214555555",
  },
  {
    id: 3,
    name: "User Name",
    desc: "Location-kochi,6th Floor Imperial, Trade Center",
    mobile: "+91 9214555555",
  },
  {
    id: 4,
    name: "User Name",
    desc: "Location-kochi,6th Floor Imperial, Trade Center",
    mobile: "+91 9214555555",
  },
];

const Users = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [event, setEvent] = useState("");
  const [restaurant, setRestaurant] = useState("");
  const [userList, setUserList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [restaurantList, setRestaurantList] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "",
  });

  useEffect(() => {
    getRestaurantList();
  }, []);

  const getRestaurantList = () => {
    Api.restaurantList("/all").then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setRestaurantList(data);
        getEventList();
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const getEventList = () => {
    Api.getEventList("/all").then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setEventList(data);
        getUserList(1);
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  const onSelectPage = (value) => {
    getUserList(value);
  };

  const getUserList = (pageNo) => {
    setIsLoading(true);
    let filter = `?pageSize=20&pageNum=${pageNo}`;
    Api.getUserList(filter).then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setUserList(data);
        setPageNum(pageNo);
        setTotalPages(response.data.pages);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  return (
    <Container
      page={"users"}
      searchVal={""}
      onSearch={() => null}
      searchLabel="Users"
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <>
          <Box sx={styles.titleMainBox}>
            <Typography variant={"h6"} sx={styles.userText}>
              Users
            </Typography>
          </Box>

          <Card sx={styles.mainGrid}>
            <Grid container direction="row" justifyContent="space-around">
              <Grid item xs={12} md={5.7}>
                <Box style={{ marginBottom: 7 }}>
                  <label style={styles.labelTitle}>Events</label>
                </Box>
                <Select
                  fullWidth
                  disableUnderline
                  variant="filled"
                  size="small"
                  sx={styles.selectList}
                  value={event}
                  onChange={(event) => setEvent(event.target.value)}
                >
                  {eventList.map((item, index) => (
                    <MenuItem
                      sx={styles.selectListItem}
                      key={index}
                      value={item._id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={5.7} sx={{ mt: { xs: 2, md: 0 } }}>
                <Box style={{ marginBottom: 7 }}>
                  <label style={styles.labelTitle}>Restaurants</label>
                </Box>
                <Select
                  fullWidth
                  disableUnderline
                  variant="filled"
                  size="small"
                  sx={styles.selectList}
                  value={restaurant}
                  onChange={(event) => setRestaurant(event.target.value)}
                >
                  {restaurantList.map((item, index) => (
                    <MenuItem
                      sx={styles.selectListItem}
                      key={index}
                      value={item._id}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Card>

          <Grid container direction="row" mt={2}>
            {userList.map((item, index) => {
              return <UserList key={index} data={item} />;
            })}
          </Grid>
          <Page
            totalPages={totalPages}
            pageNum={pageNum}
            onSelectPage={onSelectPage}
          />
        </>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

export default Users;

const styles = {
  titleMainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 2,
  },
  userText: {
    fontFamily: "GilroyBold",
  },
  mainGrid: {
    cursor: "pointer",
    borderRadius: 3,
    width: "100%",
    marginBottom: 2,
    padding: 1.3,
  },
  labelTitle: {
    fontFamily: "GilroyBold",
    fontSize: 14,
  },
  selectList: {
    "& .MuiSelect-select": {
      display: "flex",
      alignItems: "center",
      fontFamily: "GilroySemiBold",
      fontSize: 13,
      paddingTop: 0.8,
      paddingBottom: 0.8,
    },
  },
  selectListItem: {
    fontFamily: "GilroySemiBold",
    color: THEME_COLOR3,
    fontSize: 13,
  },
};
