import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import "@google/model-viewer";
import Button from "@mui/material/Button";

import styles from "./AddArModel.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import UploadArModel from "../../components/UploadArModel";
import SliderInput from "../../components/SliderInput";
import RestaurantHeader from "../../components/RestaurantHeader";

const AddArModel = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantData, setRestaurantData] = useState(null);
  const [menuId, setMenuId] = useState("");
  const [menuData, setMenuData] = useState(null);
  const [modelData, setModelData] = useState(null);
  const [uploadData, setUploadData] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [modelZoom, setModelZoom] = useState(0);
  const [lighting, setLighting] = useState([]);
  const [verticalTiltAngle, setVerticalTiltAngle] = useState(75);
  const [horizontalTiltAngle, setHorizontalTiltAngle] = useState(0);
  const [cameraTargetYAxis, setCameraTargetYAxis] = useState(0);
  const [exposure, setExposure] = useState(1);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let params = location.pathname.split("/");
    if (params.length >= 5) {
      setRestaurantId(params[2]);
      setMenuId(params[4]);
    }
  }, []);

  useEffect(() => {
    if (restaurantId) {
      getRestaurantData();
    }
  }, [restaurantId]);

  const getRestaurantData = () => {
    Api.getRestaurantData(restaurantId).then((response) => {
      if (response.success) {
        let resData = response.data;
        setRestaurantData(resData);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  useEffect(() => {
    if (menuId != "") {
      getMenuData();
    }
  }, [menuId]);

  const getMenuData = () => {
    setIsLoading(true);
    Api.getMenuData(menuId).then((response) => {
      if (response.success) {
        let data = response.data;
        setMenuData(data);
        if (data.modelId != null) {
          getArModelData(data.modelId);
        } else {
          setIsLoading(false);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setIsLoading(false);
      }
    });
  };

  const getArModelData = (model_id) => {
    setIsLoading(true);
    Api.getArModelData(model_id).then((response) => {
      if (response.success) {
        let data = response.data;
        setModelData(data);
        let info = data?.lightingInfo;
        if (info) {
          if (info?.verticalTiltAngle) {
            setVerticalTiltAngle(info?.verticalTiltAngle);
          }
          if (info?.horizontalTiltAngle) {
            setHorizontalTiltAngle(info?.horizontalTiltAngle);
          }
          if (info?.zoom) {
            setModelZoom(info?.zoom);
          }
          if (info?.lighting?.length) {
            setLighting(info?.lighting);
          }
          if (info?.exposure) {
            setExposure(info?.exposure);
          }
          if (info?.verticalPosition) {
            setCameraTargetYAxis(info?.verticalPosition);
          }
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const uploadFile = (data, file) => {
    data["upload"] = true;
    setModelData({ ...modelData, modelPath: { glb: data.image } });
    setUploadData(file);
  };

  const uploadError = (err) => {
    setMsgAlert({ open: true, message: err, msgType: "error" });
  };

  const onRemoveImage = () => {
    if (modelData?._id) {
      onDeleteModel();
    } else {
      setModelData({ ...modelData, modelPath: {} });
      setUploadData(null);
    }
  };

  const onAddModel = () => {
    setImageLoading(true);
    let name = menuData.name.toLowerCase().split(" ").join("-");

    let data = {
      name: name,
      isPlane: true,
      meta: {
        restaurantID: restaurantId,
      },
    };

    let datas = JSON.stringify(data);
    Api.createArModel(datas).then((response) => {
      if (response.success) {
        uploadArModel(response.data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setImageLoading(false);
      }
    });
  };

  const uploadArModel = (data) => {
    let formData = new FormData();
    formData.append("glb", uploadData);
    Api.uploadArModel(formData, data._id).then((response) => {
      if (response.success) {
        updateMenu(data, "update");
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        setImageLoading(false);
      }
    });
  };

  const onDeleteModel = () => {
    if (modelData?._id) {
      setImageLoading(true);
      Api.deleteArModel(modelData?._id).then((response) => {
        if (response.success) {
          updateMenu(null, "delete");
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
          setImageLoading(false);
        }
      });
    }
  };

  const updateMenu = (model, type) => {
    let data1 = {};
    if (type == "delete") {
      data1 = {
        modelId: null,
      };
    } else {
      data1 = {
        modelId: model._id,
      };
    }
    let datas = JSON.stringify(data1);

    Api.updateMenu(datas, menuId).then((response) => {
      if (response.success) {
        if (type == "delete") {
          setModelData(null);
          setModelZoom(0);
          setLighting([]);
          setVerticalTiltAngle(75);
          setHorizontalTiltAngle(0);
          setCameraTargetYAxis(0);
          setExposure(1);
        } else {
          setModelData({ ...modelData, ...model });
        }
        setMsgAlert({
          open: true,
          message:
            type == "delete" ? "Deleted Successfully" : "Uploaded Successfully",
          msgType: "success",
        });
        setUploadData(null);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setImageLoading(false);
    });
  };

  const updateArModelAttribute = () => {
    let data = {
      lightingInfo: {
        verticalTiltAngle: verticalTiltAngle,
        horizontalTiltAngle: horizontalTiltAngle,
        zoom: modelZoom,
        lighting: lighting,
        exposure: exposure,
        verticalPosition: cameraTargetYAxis,
      },
      meta: {
        restaurantID: restaurantId,
      },
    };

    let datas = JSON.stringify(data);
    Api.updateArModelAttribute(datas, modelData?._id).then((response) => {
      if (response.success) {
        setMsgAlert({
          open: true,
          message: "Updated Successfully",
          msgType: "success",
        });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setImageLoading(false);
    });
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={12} mb={2}>
            <RestaurantHeader
              title={menuData?.name}
              onBackBtnClick={() =>
                navigate("/restaurant/" + restaurantId + "/ar_models")
              }
            />
          </Grid>
          <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
            <Grid container item xs={12} md={12} justifyContent="space-between">
              <Grid container item xs={12} md={6}>
                <Grid
                  container
                  item
                  xs={12}
                  md={12}
                  style={{ flex: 1 }}
                  justifyContent="center"
                >
                  <UploadArModel
                    uploadText={"Drag and Drop Your Model Here"}
                    imageLoading={imageLoading}
                    arModel={
                      modelData?.modelPath?.glb ? modelData?.modelPath?.glb : ""
                    }
                    maxFiles={1}
                    lighting={lighting}
                    modelZoom={modelZoom}
                    horizontalTiltAngle={horizontalTiltAngle}
                    exposure={exposure}
                    verticalTiltAngle={verticalTiltAngle}
                    cameraTargetYAxis={cameraTargetYAxis}
                    bgImage={restaurantData?.arModelBg ?? null}
                    uploadError={uploadError}
                    uploadFile={uploadFile}
                  />
                </Grid>
                <Grid item xs={12} md={12} justifyContent="center">
                  <Box className={styles.mainBtnView} my={2}>
                    <Box
                      className={styles.btnView}
                      onClick={imageLoading ? null : onRemoveImage}
                    >
                      <Typography className={styles.btnText}>
                        {modelData?._id ? "Delete Model" : "Cancel"}
                      </Typography>
                    </Box>
                    <Box
                      className={styles.addBtnView}
                      sx={{
                        backgroundColor:
                          imageLoading || uploadData == null ? "#CCC" : "#000",
                      }}
                      onClick={
                        imageLoading || uploadData == null ? null : onAddModel
                      }
                      ml={1}
                    >
                      <Typography className={styles.addBtnText}>
                        {imageLoading
                          ? "Please Wait"
                          : modelData?._id
                          ? "Update Model"
                          : "Upload"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container item xs={12} md={5} style={{ flex: 1 }}>
                <Grid container item xs={12}>
                  <Grid item xs={12} mt={2} mb={1}>
                    <SliderInput
                      label={"Model Zoom: " + modelZoom}
                      value={modelZoom}
                      setValue={setModelZoom}
                      min={0}
                      max={180}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1} mb={1}>
                    <SliderInput
                      label={"Horizontal Tilt Angle: " + horizontalTiltAngle}
                      value={horizontalTiltAngle}
                      setValue={setHorizontalTiltAngle}
                      min={0}
                      max={360}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1} mb={1}>
                    <SliderInput
                      label={"Vertical Tilt Angle: " + verticalTiltAngle}
                      value={verticalTiltAngle}
                      setValue={setVerticalTiltAngle}
                      min={0}
                      max={90}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1} mb={1}>
                    <SliderInput
                      label={"Exposure: " + exposure}
                      value={exposure}
                      setValue={setExposure}
                      step={0.01}
                      min={0}
                      max={2}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1} mb={1}>
                    <SliderInput
                      label={`Lighting: ${lighting?.join(", ")}`}
                      value={lighting?.[0] ?? 0}
                      setValue={(val) => setLighting([val, val, val])}
                      step={0.1}
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={12} mt={2} mb={1}>
                    <SliderInput
                      label={`Vertical Position: ${cameraTargetYAxis}`}
                      value={cameraTargetYAxis}
                      setValue={(val) => setCameraTargetYAxis(val)}
                      step={0.01}
                      min={-1}
                      max={1}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} mt={2} justifyContent="flex-end">
                  <Button
                    type="button"
                    className={styles.submitBtn}
                    onClick={() =>
                      modelData?._id ? updateArModelAttribute() : null
                    }
                    sx={{
                      ml: 1,
                      color: "#FFFFFF",
                      borderRadius: 1,
                      "&:hover": {
                        backgroundColor: modelData?._id
                          ? "#000000"
                          : "#AAA" + " !important",
                      },
                      cursor: modelData?._id ? "pointer" : "default",
                      backgroundColor: modelData?._id ? "#000000" : "#AAA",
                    }}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <AlertMsg
            msgAlert={msgAlert}
            onCloseAlertMsg={() =>
              setMsgAlert({ open: false, message: "", msgType: "error" })
            }
          />
        </Grid>
      )}
    </Container>
  );
};

export default AddArModel;
