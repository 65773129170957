import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";

export default function SliderInput(props) {
  const handleChange = (e, newValue) => {
    props.setValue(newValue);
  };

  return (
    <Box style={{ width: "100%" }}>
      {props?.label ? (
        <Box mb={0.8}>
          <Typography
            sx={[
              {
                color: '#000',
                fontSize: 12,
                fontFamily: "GilroyMedium",
              },
              props?.labelStyle ? props.labelStyle : null,
            ]}
          >
            {props.label}
          </Typography>
        </Box>
      ) : null}
      <Slider
        value={props.value}
        min={props.min}
        max={props.max}
        step={props?.step ? props?.step : 1}
        onChange={handleChange}
      />
      <Box mt={-2} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          onClick={() => props.setValue(props.min)}
          sx={{
            cursor: "pointer",
            color: "#000",
            fontSize: 12,
            fontFamily: "GilroySemiBold",
          }}
        >
          {props.min}
        </Typography>
        <Typography
          onClick={() => props.setValue(props.max)}
          sx={{
            cursor: "pointer",
            color: "#000",
            fontSize: 12,
            fontFamily: "GilroySemiBold",
          }}
        >
          {props.max}
        </Typography>
      </Box>
    </Box>
  );
}
