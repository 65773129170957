import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./GiftCards.module.css";

import * as Api from "../../../api";

import { connect } from "react-redux";

import Container from "../../../components/Container";
import UploadFile from "../../../components/UploadFile";
import AlertMsg from "../../../components/AlertMsg";
import Loader from "../../../components/Loader";

import { THEME_COLOR, BTN_COLOR } from "../../../constants";

import { ReactComponent as LeftArrowIcon } from "../../../assets/images/left_arrow1.svg";

const GiftCards = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [isLoading, setIsLoading] = useState(true);
  const [giftCardFrontImage, setGiftCardFrontImage] = useState([]);
  const [giftCardFrontFile, setGiftCardFrontFile] = useState(null);
  const [giftCardBackImage, setGiftCardBackImage] = useState([]);
  const [giftCardBackFile, setGiftCardBackFile] = useState(null);
  const [giftCardData, setGiftCardData] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getGiftCardTemplate();
  }, []);

  const getGiftCardTemplate = () => {
    setIsLoading(true);
    let filter = "?pageSize=1&pageNum=1";
    Api.getGiftCardTemplate(filter, restaurantId).then((response) => {
      if (response.success) {
        if (response.data.rows.length) {
          let data = response.data.rows[0];
          let frontIndex = data.frontImage.lastIndexOf("/") + 1;
          let frontFilename = data.frontImage.substr(frontIndex);
          let front_img = {
            id: new Date(),
            name: frontFilename,
            displayName: frontFilename,
            size: 0,
            image: data.frontImage,
            upload: true,
          };

          let backIndex = data.backImage.lastIndexOf("/") + 1;
          let backFilename = data.backImage.substr(backIndex);
          let back_img = {
            id: new Date(),
            name: backFilename,
            displayName: backFilename,
            size: 0,
            image: data.backImage,
            upload: true,
          };

          setGiftCardData(data);
          setGiftCardFrontImage([front_img]);
          setGiftCardBackImage([back_img]);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const setFrontFile = (data, file) => {
    data["upload"] = true;
    setGiftCardFrontImage((prev) => [...prev, data]);
    setGiftCardFrontFile(file);
  };

  const setBackFile = (data, file) => {
    data["upload"] = true;
    setGiftCardBackImage((prev) => [...prev, data]);
    setGiftCardBackFile(file);
  };

  const onRemoveFrontImage = (data) => {
    setGiftCardFrontImage([]);
    setGiftCardFrontFile(null);
  };

  const uploadFrontError = (err) => {
    setMsgAlert({ open: true, message: err, msgType: "error" });
  };

  const onRemoveBackImage = (data) => {
    setGiftCardBackImage([]);
    setGiftCardBackFile(null);
  };

  const uploadBackError = (err) => {
    setMsgAlert({ open: true, message: err, msgType: "error" });
  };

  function getRandom(length) {
    let result = "";
    const characters = "0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  const uploadFileApi = (file) => {
    let formData = new FormData();
    formData.append("image", file);
    return Api.uploadImageFile(formData).then((response) => {
      return response;
    });
  };

  const onSubmitGiftCard = async () => {
    let frontImg = "";
    let backImg = "";

    if (giftCardData == null) {
      if (giftCardFrontFile == null) {
        setMsgAlert({
          open: true,
          message: "Upload Front Image",
          msgType: "error",
        });
        return;
      }

      if (giftCardBackFile == null) {
        setMsgAlert({
          open: true,
          message: "Upload Back Image",
          msgType: "error",
        });
        return;
      }
    } else {
      if (giftCardFrontImage.length == 0) {
        setMsgAlert({
          open: true,
          message: "Upload Front Image",
          msgType: "error",
        });
        return;
      }

      if (giftCardBackImage.length == 0) {
        setMsgAlert({
          open: true,
          message: "Upload Back Image",
          msgType: "error",
        });
        return;
      }
    }

    setIsLoading(true);
    if (giftCardFrontFile) {
      let frontImgRes = await uploadFileApi(giftCardFrontFile);
      if (frontImgRes.success) {
        frontImg = frontImgRes.data.location;
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: frontImgRes.msg, msgType: "error" });
        return;
      }
    } else {
      if (giftCardFrontImage.length && giftCardData != null) {
        frontImg = giftCardFrontImage[0]["image"];
      }
    }

    if (giftCardBackFile) {
      let backImRes = await uploadFileApi(giftCardBackFile);
      if (backImRes.success) {
        backImg = backImRes.data.location;
      } else {
        setIsLoading(false);
        setMsgAlert({ open: true, message: backImRes.msg, msgType: "error" });
        return;
      }
    } else {
      if (giftCardBackImage.length && giftCardData != null) {
        backImg = giftCardBackImage[0]["image"];
      }
    }

    if (frontImg == "" && backImg == "") {
      setIsLoading(false);
      return;
    }

    let data1 = {
      frontImage: frontImg,
      backImage: backImg,
    };
    if (giftCardData == null) {
      data1 = {
        _idRestaurant: restaurantId,
        name: "template1",
        cardNumberSeries: getRandom(4),
        cardSequenceValue: 1000,
        ...data1,
      };
    }

    let datas = JSON.stringify(data1);

    Api.addUpdateGiftCardTemplate(
      datas,
      restaurantId,
      giftCardData == null ? null : giftCardData._id
    ).then((response) => {
      if (response.success) {
        let imgFront = frontImg;
        let imgBack = backImg;
        if (giftCardData == null) {
          let data = response.data;
          setGiftCardData(data);
        }

        let frontIndex = imgFront.lastIndexOf("/") + 1;
        let frontFilename = imgFront.substr(frontIndex);
        let front_img = {
          id: new Date(),
          name: frontFilename,
          displayName: frontFilename,
          size: 0,
          image: imgFront,
          upload: true,
        };

        let backIndex = imgBack.lastIndexOf("/") + 1;
        let backFilename = imgBack.substr(backIndex);
        let back_img = {
          id: new Date() + Math.random(),
          name: backFilename,
          displayName: backFilename,
          size: 0,
          image: imgBack,
          upload: true,
        };

        setGiftCardFrontImage([front_img]);
        setGiftCardBackImage([back_img]);

        setGiftCardBackFile(null);
        setGiftCardFrontFile(null);

        setMsgAlert({
          open: true,
          message:
            "Gift card " +
            (giftCardData ? "updated" : "added") +
            " Successfully",
          msgType: "success",
        });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  const onDeleteGiftCardTemplate = () => {
    setIsLoading(true);
    Api.deleteGiftCardTemplate(restaurantId, giftCardData?._id).then(
      (response) => {
        if (response.success) {
          setGiftCardBackImage([]);
          setGiftCardBackFile(null);
          setGiftCardFrontImage([]);
          setGiftCardFrontFile(null);
          setGiftCardData(null);
          setMsgAlert({
            open: true,
            message: "Gift card deleted Successfully",
            msgType: "success",
          });
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setIsLoading(false);
      }
    );
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel="Restaurants"
    >
      <Grid container display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Box
            sx={{ backgroundColor: THEME_COLOR }}
            className={styles.leftArrowBox}
            onClick={() => navigate(-1)}
          >
            <LeftArrowIcon height={15} width={15} />
          </Box>
          <Typography variant={"h6"} className={styles.restaurantInfo} ml={2}>
            Gift Cards - {props.restaurantData?.name}
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Box
            sx={{ backgroundColor: BTN_COLOR }}
            className={styles.transactionsBtn}
            onClick={() =>
              navigate(
                "/restaurant/" + restaurantId + "/gift_card/transactions"
              )
            }
          >
            <Typography className={styles.transactionsBtnText}>
              Gift Cards Transactions
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid container alignItems="center" justifyContent="center" mt={3}>
        {isLoading ? (
          <Loader height={{ height: window.innerHeight - 200 }} />
        ) : (
          <Card elevation={0} className="add-card">
            <Grid container justifyContent={"center"}>
              <Grid item md={5} mr={2}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ marginBottom: 1.5, marginTop: 2 }}
                >
                  <Typography className={styles.restaurantInfo}>
                    Front
                  </Typography>
                </Box>
                <UploadFile
                  page={"addResItemImg"}
                  uploadText={"Drag and Drop Your file Here"}
                  imageLoading={false}
                  imageList={giftCardFrontImage}
                  uploadFile={setFrontFile}
                  maxFiles={1}
                  uploadHeight={300}
                  uploadError={uploadFrontError}
                  onRemoveImage={onRemoveFrontImage}
                />
              </Grid>
              <Grid item md={5} mr={2}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  sx={{ marginBottom: 1.5, marginTop: 2 }}
                >
                  <Typography className={styles.restaurantInfo}>
                    Back
                  </Typography>
                </Box>
                <UploadFile
                  page={"addResItemImg"}
                  uploadText={"Drag and Drop Your file Here"}
                  imageLoading={false}
                  imageList={giftCardBackImage}
                  uploadFile={setBackFile}
                  maxFiles={1}
                  uploadHeight={300}
                  uploadError={uploadBackError}
                  onRemoveImage={onRemoveBackImage}
                />
              </Grid>
            </Grid>
            {giftCardData == null ? (
              <Box
                mt={3}
                sx={{
                  backgroundColor: BTN_COLOR,
                  height: 50,
                  justifyContent: "center",
                  minWidth: 140,
                }}
                className={styles.transactionsBtn}
                onClick={() => {
                  onSubmitGiftCard();
                }}
              >
                <Typography className={styles.btnText}>Submit</Typography>
              </Box>
            ) : (
              <Box display={"flex"} mt={3} justifyContent={"space-between"}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    height: 50,
                    justifyContent: "center",
                    minWidth: 140,
                  }}
                  className={styles.imageDeleteBtn}
                  onClick={() => {
                    onDeleteGiftCardTemplate();
                  }}
                >
                  <Typography className={styles.imageDeleteBtnText}>
                    Delete
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: BTN_COLOR,
                    height: 50,
                    justifyContent: "center",
                    minWidth: 140,
                  }}
                  ml={1}
                  className={styles.transactionsBtn}
                  onClick={() => {
                    onSubmitGiftCard();
                  }}
                >
                  <Typography className={styles.btnText}>Update</Typography>
                </Box>
              </Box>
            )}
          </Card>
        )}
      </Grid>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantData: state.userData.restaurantData,
  };
};

export default connect(mapStateToProps, null)(GiftCards);
