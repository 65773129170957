import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";
import {createTheme, ThemeProvider} from '@mui/material/styles';

import {connect} from "react-redux";
import {
  updateUserToken,
  updateRefreshToken,
} from '../../redux/actions/userDataActions';

import * as Api from '../../api/index';

import '../../assets/css/custom.css';

import {THEME_COLOR} from "../../constants";

import {ReactComponent as Logo} from '../../assets/images/logo.svg';
import {ReactComponent as Lock} from '../../assets/images/lock.svg';
import {ReactComponent as Email} from '../../assets/images/email.svg';
import BgImage from '../../assets/images/bg_image.png';
import AlertMsg from "../../components/AlertMsg";

const theme = createTheme({
  palette: {
    white: {
      main: '#fff',
    },
  },
});

const Login = (props) => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [email, setEmail] = useState('');
  const [pswd, setPswd] = useState('');
  const [msgAlert, setMsgAlert] = useState({open: false, message: '', msgType: 'error'});

  const navigate = useNavigate();

  const onCloseAlertMsg = () => {
    setMsgAlert({open: false, message: '', msgType: 'error'});
  };

  const onLogin = (e) => {
    setMsgAlert({open: false, message: '', msgType: 'error'});

    e.preventDefault();
    let data = JSON.stringify({
      email: email,
      password: pswd
    });
    setBtnDisabled(true);
    Api.login(data).then((response) => {
      if (response.success) {
        props.updateRefreshToken(response.data.refreshToken);
        props.updateUserToken(response.data.token);
        navigate("/");
      } else {
        setBtnDisabled(false)
        setMsgAlert({open: true, message: response.msg, msgType: 'error'});
      }
    });
  };

  return (
    <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="login-main-grid"
          style={{
            backgroundImage: `url(${BgImage})`,
            backgroundSize: 'cover',
          }}>
      <Grid item xs></Grid>
      <Grid item xs={10} md={2.5} className={'login-grid-height'}>
        <Grid className={'login-form-main-section'}>
          <Grid>
            <Grid align='center' className="login-logo-section" sx={{backgroundColor: THEME_COLOR}}>
              <Logo width={'50%'} height={'50%'} className="login-logo"/>
            </Grid>

            <Grid className="login-form-section" component="form"
                  onSubmit={onLogin}>
              <ThemeProvider theme={theme}>
                <TextField
                  id="email"
                  color="white"
                  type="email"
                  focused
                  fullWidth
                  required
                  onChange={e => setEmail(e.target.value)}
                  InputProps={{
                    autoComplete: 'none',
                    style: {
                      fontSize: 14,
                      fontFamily: 'GilroySemiBold'
                    },
                    startAdornment: (
                      <InputAdornment position="start" sx={styles.iconPos}>
                        <Email height={20} width={20}/>
                      </InputAdornment>
                    )
                  }}
                  style={{marginBottom: 20}}
                  variant="standard"/>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <TextField
                  id="password"
                  type="password"
                  color="white"
                  focused
                  fullWidth
                  required
                  onChange={e => setPswd(e.target.value)}
                  InputProps={{
                    autoComplete: 'none',
                    style: {
                      fontSize: 14,
                      fontFamily: 'GilroySemiBold'
                    },
                    startAdornment: (
                      <InputAdornment position="start" sx={styles.iconPos}>
                        <Lock height={20} width={20}/>
                      </InputAdornment>
                    )
                  }}
                  style={{marginBottom: 20}}
                  variant="standard"/>
              </ThemeProvider>
              <Button type='submit'
                      variant="contained"
                      disabled={btnDisabled}
                      style={{
                        backgroundColor: THEME_COLOR,
                        color: '#000',
                        fontFamily: 'GilroySemiBold',
                        textTransform: 'capitalize',
                        borderRadius: 6,
                        marginTop: 20,
                      }}
                      fullWidth>
                {btnDisabled ? 'Please Wait' : 'Login'}
              </Button>
              {(msgAlert.msgType == 'error' && msgAlert.message != '') ?
                <Typography style={styles.msgAlertText}>{msgAlert.message}</Typography> : null}
            </Grid>

            {/*<Typography sx={styles.cantLoginText}>*/}
            {/*  Can't Login?*/}
            {/*</Typography>*/}
          </Grid>
        </Grid>
        <Typography sx={styles.copyright}>
          Copyright © 2010-2022 Foodo Company. All rights reserved.
        </Typography>
      </Grid>
      <Grid item xs></Grid>
      {msgAlert.msgType != 'error' ?
        <AlertMsg msgAlert={msgAlert} onCloseAlertMsg={onCloseAlertMsg}/> : null}
    </Grid>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateUserToken: (data) => dispatch(updateUserToken(data)),
    updateRefreshToken: (data) => dispatch(updateRefreshToken(data)),
  }
};

export default connect(null, mapDispatchToProps)(Login);

const styles = {
  iconPos: {
    paddingLeft: 1.3,
    paddingRight: 1
  },
  cantLoginText: {
    color: THEME_COLOR,
    fontFamily: 'GilroyBold',
    textAlign: 'center',
    fontSize: 11,
    marginTop: 3
  },
  copyright: {
    fontFamily: 'GilroyRegular',
    textAlign: 'center',
    color: '#fff',
    fontSize: 10,
  },
  msgAlertText: {
    fontFamily: 'GilroySemiBold',
    fontSize: 12.5,
    textAlign: 'center',
    color: '#900707',
    lineHeight: 1,
    marginTop: 9
  },
};
