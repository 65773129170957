import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import styles from "./AddOptionItemInput.module.css";

import SettingTextField from "../SettingTextField";

import { ReactComponent as PlusIcon } from "../../assets/images/plus2.svg";

const AddOptionItemInput = (props) => {
  const [itemName, setItemName] = useState("");
  const [error, setError] = useState("");

  const addItemClick = () => {
    setError("");
    if (itemName.trim() == "") {
      setError("Item name required");
      return;
    }
    let index = props.option?.items?.findIndex(x => itemName.toLowerCase() == x.toLowerCase());
    if(index >= 0) {
      setError("Item name already exist");
      return;
    }
    setItemName("");
    props.onAddItem(itemName, props.option);
  };

  return (
    <Box className={styles.mainView}>
      <SettingTextField
        page={"addOption"}
        value={itemName}
        setValue={setItemName}
        placeholder={"Enter item name"}
        placeholderColor={"#BBBBBB"}
        border={"1px solid #BBBBBB"}
        required={false}
        variant={"filled"}
        error={error}
      />
      <Box
        onClick={addItemClick}
        className={styles.actionBtn}
        ml={1}
      >
        <Typography
          className={styles.actionBtnText}
          mr={1}
        >
          Add Item
        </Typography>
        <PlusIcon width={14} height={14} />
      </Box>
    </Box>
  );
};

export default AddOptionItemInput;
