import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./ArModelSelectMenu.module.css";

import Container from "../../components/Container";
import CategoryMenuList from "../../components/restaurant/CategoryMenuList";
import RestaurantHeader from "../../components/RestaurantHeader";

const ArModelSelectMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [searchMenu, setSearchMenu] = useState("");

  const onSelectMenu = (item) => {
    navigate("/restaurant/" + restaurantId + "/model/" + item._id);
  };

  const onSearchMenu = (val) => {
    setSearchMenu(val);
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={onSearchMenu}
      searchLabel="Menu"
    >
      <Grid container item xs={12}>
        <Grid item xs={12} mb={2}>
          <RestaurantHeader
            title={"Select the menu"}
            onBackBtnClick={() =>
              navigate("/restaurant/" + restaurantId + "/ar_models")
            }
          />
        </Grid>
        <Grid item xs={12} className={styles.mainView} p={2} pt={3}>
          <CategoryMenuList
            page={"ArModelSelectMenu"}
            restaurantId={restaurantId}
            searchMenu={searchMenu}
            onSelectMenu={onSelectMenu}
            allowSort={false}
            showGridIcon={true}
            showMenuPopup={false}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ArModelSelectMenu;
