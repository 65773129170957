import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import {createTheme, ThemeProvider} from "@mui/material/styles";

import {ReactComponent as CloseIcon} from './../assets/images/close.svg';
import {ReactComponent as TickIcon} from './../assets/images/tick.svg';

import './../assets/css/custom.css';

import {THEME_COLOR, THEME_COLOR3} from "../constants";

const theme = createTheme({
  palette: {
    theme_color: {
      main: THEME_COLOR,
    },
  },
});

const AddMenuImageItem = (props) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // const timer = setInterval(() => {
    //   setProgress((oldProgress) => {
    //     if (oldProgress === 100) {
    //       return 0;
    //     }
    //     const diff = Math.random() * 10;
    //     return Math.min(oldProgress + diff, 100);
    //   });
    // }, 500);
    //
    // return () => {
    //   clearInterval(timer);
    // };
  }, []);

  let data = props.data;

  return (
    <Grid container direction={'row'} style={{marginBottom: 17, width: '90%', alignItems: 'center'}}>
      <Grid item xs={3} style={{display: 'flex', alignItems: 'center'}}>
        <img alt="complex" src={data.image} style={{height: 55, width: '100%', borderRadius: 10}}/>
      </Grid>
      <Grid container direction="row" item xs={9}>
        <Grid item xs={10} style={{paddingLeft: 14}}>
          <Typography sx={styles.nameText}>{data.name}</Typography>
          <Typography sx={styles.sizeText}>{data.size} MB</Typography>
          {data.upload ?
            <Grid style={{
              backgroundColor: THEME_COLOR,
              borderRadius: 8,
              width: 13,
              height: 13,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}>
              <TickIcon width={9} height={9}/>
            </Grid>
            : (<ThemeProvider theme={theme}>
              {/*<Box sx={{width: '100%'}}>*/}
              {/*  <LinearProgress variant="determinate" color="theme_color" value={progress}/>*/}
              {/*</Box>*/}
            </ThemeProvider>)}
        </Grid>

        <Grid item xs={2}>
          <Grid style={{
            backgroundColor: 'red',
            borderRadius: 8,
            width: 15,
            height: 15,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
          }}>
            <CloseIcon width={10} height={10} onClick={() => props.onRemoveImage(data)}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddMenuImageItem;

const styles = {
  nameText: {
    fontFamily: 'GilroyBold',
    fontSize: 12,
    wordBreak: 'break-all'
  },
  sizeText: {
    color: THEME_COLOR3,
    fontFamily: 'GilroySemiBold',
    fontSize: 10,
    mt: 0.2,
    mb: 0.4
  }
};
