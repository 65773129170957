import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";

import SettingTextField from "../SettingTextField";

import { ReactComponent as PlusIcon } from "../../assets/images/plus2.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit2.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete_white.svg";
import { ReactComponent as Delete1Icon } from "../../assets/images/delete_black.svg";
import { ReactComponent as CircleIcon } from "../../assets/images/circle.svg";
import { ReactComponent as CircleDotIcon } from "../../assets/images/circle_dot.svg";
import { ReactComponent as ExpandVerticalIcon } from "../../assets/images/expand_vertical.svg";

import { CURRENCY_SYMBOL } from "../../constants";

import styles from "./AddModifiers.module.css";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#455A64",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

let ACTION_BTN_LIST = [
  {
    _id: "add_item",
    label: "Add Item",
    icon: <PlusIcon width={14} height={14} />,
  },
  {
    _id: "edit_item",
    label: "Edit Item",
    icon: <EditIcon width={14} height={14} />,
  },
  {
    _id: "delete",
    label: "Delete",
    icon: <DeleteIcon width={14} height={14} />,
  },
];

let ACTION_BTN_LIST2 = [
  {
    _id: "add_item",
    label: "Add Item",
  },
  {
    _id: "save_modifier",
    label: "Save Modifier",
  },
];

const ModifierItemList = (props) => {
  let {
    item,
    modifierName,
    setModifierName,
    modifierError,
    editItemData,
    editModifierName,
    setEditModifierName,
    editModifierError,
    onActionBtnClick,
    onRemoveMenuItem,
    onSortEndMenuItem,
  } = props;

  const handleOnActionBtnClick = (type) => {
    onActionBtnClick(item, { _id: type });
  };

  return (
    <Box mb={1} className={styles.modifierView}>
      <SortableKnob>
        <Box
          px={1.4}
          py={1.3}
          className={styles.modifierTitleMainView}
          sx={{ cursor: item._id == "add" ? "default" : "pointer" }}
        >
          <Box
            className={styles.modifierTitleView}
            pr={item._id == "add" ? 0 : 2}
          >
            {item._id == "add" ? (
              <SettingTextField
                page={"addModifier"}
                value={modifierName}
                setValue={setModifierName}
                placeholder={"Enter New Modifier"}
                placeholderColor={"#BBBBBB"}
                border={"1px solid #BBBBBB"}
                required={false}
                variant={"filled"}
                error={modifierError}
              />
            ) : editItemData?._id == item._id ? (
              <SettingTextField
                page={"addModifier"}
                value={editModifierName}
                setValue={setEditModifierName}
                placeholder={"Enter New Modifier"}
                placeholderColor={"#BBBBBB"}
                border={"1px solid #BBBBBB"}
                required={false}
                variant={"filled"}
                error={editModifierError}
              />
            ) : (
              <Typography className={styles.modifierTitleText}>
                {item.header}
              </Typography>
            )}
          </Box>
          {item._id == "add" ? null : (
            <ExpandVerticalIcon width={20} height={20} />
          )}
        </Box>
      </SortableKnob>
      <Box
        px={1.4}
        py={1.3}
        className={styles.modifierTitleMainView}
        justifyContent="space-between"
      >
        <Box className={styles.requiredView} key={"multi_selection"}>
          <Typography className={styles.requiredText} mr={1.5}>
            Multi Selection
          </Typography>
          <AntSwitch
            checked={item?.multiSelection}
            onChange={() => handleOnActionBtnClick("multi_selection")}
            inputProps={{ "aria-label": "ant design" }}
          />
        </Box>
        <Box ml={2} className={styles.requiredView} key={"extra_allowed"}>
          <Typography className={styles.requiredText} mr={1.5}>
            Extra Allowed
          </Typography>
          <AntSwitch
            checked={item?.extraAllowed}
            onChange={() => handleOnActionBtnClick("extra_allowed")}
            inputProps={{ "aria-label": "ant design" }}
          />
        </Box>
        <Box ml={2} className={styles.requiredView} key={"required"}>
          <Typography className={styles.requiredText} mr={1.5}>
            Is Required
          </Typography>
          <AntSwitch
            checked={item.required}
            onChange={() => handleOnActionBtnClick("required")}
            inputProps={{ "aria-label": "ant design" }}
          />
        </Box>
      </Box>
      <Box
        px={1.4}
        py={1.3}
        className={styles.modifierTitleMainView}
        justifyContent="space-between"
      >
        <Box className={styles.requiredView} key={"multi_selection"}>
          <Typography className={styles.requiredText} mr={1.5}>
            Add Up Prices
          </Typography>
          <AntSwitch
            checked={item?.addUpPrices}
            onChange={() => handleOnActionBtnClick("add_up_prices")}
            inputProps={{ "aria-label": "ant design" }}
          />
        </Box>
      </Box>
      <Box className={styles.modifierItemList}>
        <SortableList
          onSortEnd={(oldIndex, newIndex) =>
            onSortEndMenuItem(oldIndex, newIndex, item)
          }
        >
          {item?.items?.map((y, i2) => {
            let default_val = y?._id == item?.defaultSelection;
            return (
              <SortableItem key={i2}>
                <Box
                  key={i2}
                  className={styles.itemView}
                  style={{
                    borderBottom:
                      item?.items?.length == i2 + 1 ? "0" : "1px solid #D6D6D6",
                  }}
                  px={1.4}
                  py={1}
                  onClick={() => onActionBtnClick(item, { _id: "default" }, y)}
                >
                  <Box className={styles.itemNameView}>
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            marginTop: "0px !important",
                            marginBottom: "0px !important",
                            backgroundColor: "#EEE",
                            color: "#000",
                          },
                        },
                      }}
                      title={default_val ? "Remove Default" : "Set Default"}
                      placement="top"
                    >
                      {default_val ? (
                        <CircleDotIcon height={20} width={20} />
                      ) : (
                        <CircleIcon height={20} width={20} />
                      )}
                    </Tooltip>
                    <Typography ml={1} className={styles.itemNameText}>
                      {y.name}
                    </Typography>
                  </Box>
                  <Box className={styles.itemRightView}>
                    <Box className={styles.itemPriceView} mr={1}>
                      <Typography className={styles.itemPriceText}>
                        {CURRENCY_SYMBOL[y.price.currency]}
                        {y.price.value}
                      </Typography>
                    </Box>
                    <Box
                      mr={1}
                      sx={{ cursor: "pointer" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveMenuItem(item, y);
                      }}
                    >
                      <Delete1Icon width={15} height={15} />
                    </Box>
                    <ExpandVerticalIcon width={20} height={20} />
                  </Box>
                </Box>
              </SortableItem>
            );
          })}
        </SortableList>
      </Box>
      <Box my={1} px={1.4} className={styles.actionBtnView}>
        {(item._id == "add" ? ACTION_BTN_LIST2 : ACTION_BTN_LIST).map(
          (btn, i3) => {
            return (
              <Box
                key={i3}
                onClick={() => onActionBtnClick(item, btn)}
                className={styles.actionBtn}
                style={{
                  width: item._id == "add" ? "49%" : "32%",
                }}
              >
                <Typography className={styles.actionBtnText} mr={1}>
                  {btn._id == "edit_item" &&
                  editItemData != null &&
                  item._id == editItemData?._id
                    ? "Save"
                    : btn.label}
                </Typography>
                {item._id == "add" ? null : btn.icon}
              </Box>
            );
          }
        )}
      </Box>
    </Box>
  );
};

export default ModifierItemList;
