import React from 'react';
import Grid from '@mui/material/Grid';
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dropzone from 'react-dropzone'

import './../assets/css/custom.css';

import {THEME_COLOR3} from "../constants";

import {ReactComponent as UploadIcon} from "../assets/images/upload.svg";
import {ReactComponent as CloseIcon} from "../assets/images/close.svg";

const Input = styled('input')({
  display: 'none',
});

const UploadFile = (props) => {

  const uploadFile = acceptedFiles => {
    acceptedFiles.map(file => {
      let fileSize = file.size;
      let sizeMb = (fileSize / (1024 * 1024)).toFixed(3);
      let image = URL.createObjectURL(file);
      let new_id = new Date();
      let data = {
        id: new_id,
        name: file.name,
        displayName: file.name,
        size: sizeMb,
        image: image,
        upload: false
      };
      props.uploadFile(data, file);
    });
  };

  const uploadError = (err) => {
    console.log('err', err)
    if (err.length) {
      if (err[0]['errors'].length)
        if (err[0]['errors'][0]['code'] == 'too-many-files') {
          if (props.maxFiles == 1) {
            props.uploadError('Only 1 image is allowed to upload');
          } else {
            props.uploadError(`Only ${props.maxFiles} images are allowed to upload at a time`);
          }
        } else {
          props.uploadError(err[0]['errors'][0]['message']);
        }
    }
  };

  let upload_view = (props.page !== 'addResItemImg' || (props.page === 'addResItemImg' && props.imageList.length == 0));

  return (
    <Dropzone
      maxFiles={props.maxFiles}
      onDropRejected={err => uploadError(err)}
      accept={props?.accept ? props.accept : {'image/*': ['.jpeg', '.jpg', '.png']}}
      onDrop={uploadFile}>
      {({getRootProps, getInputProps}) => (
        <Grid item xs={12} container direction="column"
              className={upload_view ? !props.imageLoading ? "cursor-pointer" : "" : ""}
              sx={[{border: '1.5px solid ' + THEME_COLOR3},
                {height: props?.uploadHeight ? props.uploadHeight : 'fit-content'},
                styles.imageUploadGrid, {py: props?.uploadHeight ? 0 : upload_view ? 8 : 0}]}
              {...(upload_view) ? !props.imageLoading ? getRootProps() : null : null}>
          {props.page === 'addResItemImg' ? props.imageList.length ?
            <>
              <img src={props.imageList[0]['image']} style={{height: '100%', width: '100%', borderRadius: 10, py: 1}}/>
              {props.imageList[0].upload ?
                props.imageLoading ? null :
                  <Grid style={styles.closeIcon} onClick={() => props.onRemoveImage(props.imageList[0])}>
                    <CloseIcon width={15} height={15}/>
                  </Grid>
                : <Grid style={styles.uploading}>
                  <Box style={styles.uploadingText}>Uploading...</Box>
                </Grid>}
            </> : null : null}
          {upload_view ? <>
            {!props.imageLoading ? <input {...getInputProps()} /> : null}
            <UploadIcon height={50} width={50} style={{marginTop: 10, marginBottom: 15}}/>
            <Typography sx={styles.uploadText}>
              {props.uploadText}
            </Typography>
            <Typography sx={styles.uploadText}>OR</Typography>
            <label htmlFor="contained-button-file">
              <Grid className="image-browse-grid" sx={{border: '2px solid ' + THEME_COLOR3}}>
                <Typography className="image-browse-text"
                            sx={{fontFamily: 'GilroyBold'}}>{props.imageLoading ? 'Please wait' : 'Browse'}</Typography>
              </Grid>
            </label>
          </> : null}
        </Grid>
      )}
    </Dropzone>

  );
};

export default UploadFile;


const styles = {
  imageUploadGrid: {
    position: 'relative',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'dashed !important',
    borderRadius: '10px',
  },
  uploadText: {
    color: THEME_COLOR3,
    fontFamily: 'GilroyRegular',
    fontSize: 12,
    mb: 2,
  },
  browseText: {
    fontFamily: 'GilroyBold',
    fontSize: 12,
  },
  closeIcon: {
    backgroundColor: 'red',
    borderRadius: 8,
    width: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    position: 'absolute',
    top: -4,
    right: -4,
  },
  uploading: {
    backgroundColor: 'rgba(0,0,0, 0.3)',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    borderRadius: '10px',
  },
  uploadingText: {
    color: '#FFF',
    fontSize: 15,
    fontFamily: 'GilroyMedium',
  },
}

