import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { connect } from "react-redux";

import styles from "./RestaurantHeader.module.css";

import { ReactComponent as BackIcon } from "../assets/images/back.svg";

const RestaurantHeader = (props) => {
  const { title, restaurantData, onBackBtnClick } = props;

  return (
    <Box className={`${styles["title-view-text"]}`}>
      <Box className={`${styles["back-btn"]}`} onClick={onBackBtnClick}>
        <BackIcon width={30} height={30} />
      </Box>
      <Typography className={`${styles["title-text"]} ${styles["title"]}`}>
        {title} - {restaurantData?.name}
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    restaurantData: state.userData.restaurantData,
  };
};

export default connect(mapStateToProps, null)(RestaurantHeader);
