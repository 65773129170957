import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import { THEME_COLOR3 } from "../constants";

import { ReactComponent as UploadIcon } from "../assets/images/upload2.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close.svg";
import { ReactComponent as ViewIcon } from "../assets/images/view_icon.svg";
import { ReactComponent as Upload2Icon } from "../assets/images/upload_icon.svg";

const UploadLogo = (props) => {
  let is_view_upload = props?.showViewUploadIcon == true && props.logoImg != "";

  const onViewImg = (img) => {
    window.open(img, "_blank");
  };

  const uploadLogoFile = (event) => {
    let file = event.target.files[0];
    let fileSize = file.size;
    let sizeMb = (fileSize / (1024 * 1024)).toFixed(3);
    let image = URL.createObjectURL(file);
    let new_id = new Date() + props.type;
    let data = {
      id: new_id,
      name: file.name,
      displayName: file.name,
      size: sizeMb,
      image: image,
      upload: false,
    };
    props.uploadFile(data, file, props.type);
  };

  return (
    <Grid item xs={12} style={styles.mainView}>
      {props.logoImg == "" ? null : (
        <Box style={styles.closeIcon}>
          <CloseIcon width={10} height={10} onClick={props.onRemove} />
        </Box>
      )}
      <Box sx={{ border: "1.5px solid " + THEME_COLOR3, ...styles.imageGrid }}>
        {props.imageLoading ? (
          ""
        ) : (
          <input
            accept="image/*"
            style={{ display: "none" }}
            id={"contained-button-file-logo-" + props.type}
            onChange={uploadLogoFile}
            multiple={false}
            type="file"
          />
        )}
        <label
          htmlFor={
            is_view_upload ? "" : "contained-button-file-logo-" + props.type
          }
          style={{
            ...styles.imageUploadGrid,
            cursor: is_view_upload ? "default" : "pointer",
          }}
        >
          {props.logoImg == "" ? (
            <Box style={styles.uploadView}>
              <UploadIcon height={30} width={30} />
            </Box>
          ) : (
            <img
              src={props.logoImg.image}
              width={"100%"}
              height={120}
              style={{ objectFit: "cover" }}
            />
          )}
          {is_view_upload ? (
            <Box display="flex" style={styles.viewUploadIcon}>
              <Box
                style={{ marginRight: "7px", cursor: "pointer" }}
                onClick={() => onViewImg(props.logoImg.image)}
              >
                <ViewIcon width={33} height={33} />
              </Box>
              <label
                htmlFor={"contained-button-file-logo-" + props.type}
                style={{ cursor: "pointer" }}
              >
                <Upload2Icon width={30} height={30} />
              </label>
            </Box>
          ) : null}
          <Grid sx={styles.uploadLogoView}>
            <Typography
              className="image-browse-text"
              sx={{ fontFamily: "GilroySemiBold", color: "#FFF" }}
            >
              {props.imageLoading && props.imageType == props.type
                ? "Uploading..."
                : props?.placeholder
                ? props.placeholder
                : "UPLOAD LOGO"}
            </Typography>
          </Grid>
        </label>
      </Box>
    </Grid>
  );
};

export default UploadLogo;

const styles = {
  mainView: {
    position: "relative",
    width: "150px",
  },
  imageGrid: {
    position: "relative",
    height: "120px",
    width: "150px",
    overflow: "hidden",
    borderStyle: "dashed !important",
    borderRadius: "10px",
  },
  imageUploadGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  uploadView: {
    display: "flex",
    alignItems: "center",
    height: "100px",
  },
  uploadLogoView: {
    position: "absolute",
    backgroundColor: "#666",
    width: "100%",
    py: 0.8,
    bottom: 0,
  },
  viewUploadIcon: {
    position: "absolute",
    inset: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9,
  },
  closeIcon: {
    position: "absolute",
    backgroundColor: "red",
    borderRadius: 8,
    width: 15,
    height: 15,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    top: -5,
    right: -5,
    zIndex: 5,
  },
};
