import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";

import * as Api from "../../api";

import "../../assets/css/custom.css";

import Loader from "../Loader";

import { BTN_COLOR, BTN_TEXT_COLOR } from "../../constants";

import { ReactComponent as MoveIcon } from "../../assets/images/move.svg";

const EditCategoryOrder = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const { restaurantId } = props;

  useEffect(() => {
    setCategoryList(props.categoryList);
  }, [props.categoryList]);

  const onSortEnd = (oldIndex, newIndex) => {
    setCategoryList((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const handlerOnSubmit = async () => {
    setIsLoading(true);
    let dataList = categoryList.map((x, i) => {
      return { order: i + 1, _idCategory: x._id };
    });

    let data = JSON.stringify(dataList);
    const response = await Api.categoryRearrange(data, restaurantId);
    if (response.success) {
      props.onSubmit();
    } else {
      props.setMsgAlert({
        open: true,
        message: response.msg,
        msgType: "error",
      });
    }
    setIsLoading(false);
  };

  return (
    <Grid container direction="column" sx={styles.mainGrid}>
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 350 }} />
      ) : (
        <>
          <SortableList
            onSortEnd={onSortEnd}
            className="catOrderListView"
            draggedItemClassName="catOrderDragged"
          >
            {categoryList.map((x) => {
              return (
                <SortableItem key={x._id}>
                  <Box sx={styles.catBoxView}>
                    <Box style={styles.catText}>{x.name}</Box>
                    <MoveIcon width={20} height={20} />
                  </Box>
                </SortableItem>
              );
            })}
          </SortableList>
          <Grid container direction="row" justifyContent="center" mt={5}>
            <Grid
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Button
                type="button"
                sx={styles.cancelBtn}
                onClick={() => props.onCancel()}
              >
                Cancel
              </Button>
              <Button
                onClick={handlerOnSubmit}
                disabled={btnDisabled}
                type="button"
                sx={styles.saveBtn}
              >
                {btnDisabled ? "Please Wait" : "Save"}
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default EditCategoryOrder;

const styles = {
  mainGrid: {
    width: "100%",
    minHeight: "100%",
  },
  titleText: {
    fontFamily: "GilroyBold",
    fontSize: 18,
    pt: 1,
    pb: 3,
  },
  catBoxView: {
    backgroundColor: "#FFF",
    width: "fit-content",
    minWidth: 25,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    border: 1,
    borderRadius: 5,
    cursor: "grab",
    px: 2,
    py: 1,
    mt: 1,
    mr: 1,
  },
  catText: {
    marginRight: 6,
    fontSize: 11,
    fontFamily: "GilroySemiBold",
  },
  cancelBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    backgroundColor: "#FFF",
    color: "#000",
    fontFamily: "GilroyBold",
    fontSize: 14,
    borderRadius: 1,
    border: "2px solid #000000",
    width: "49%",
    height: 44,
    "&:hover": {
      backgroundColor: "#FFF !important",
    },
  },
  saveBtn: {
    backgroundColor: BTN_COLOR,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    textTransform: "capitalize",
    color: BTN_TEXT_COLOR,
    fontFamily: "GilroyBold",
    fontSize: 14,
    borderRadius: 1,
    width: "49%",
    height: 44,
    "&:hover": {
      backgroundColor: BTN_COLOR + " !important",
    },
  },
};
