import React, { useEffect, useRef } from "react";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "touchstart",
];

const SessionTimeout = ({ children, userToken }) => {
  let timer;
  let userTokenRef = useRef();

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, handleListener);
      });
      // logs out user
      logoutAction();
    }, 300000); // 5mins
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    userTokenRef.current = userToken;
    if (userTokenRef.current) {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.addEventListener(item, handleListener);
      });

      return () =>
        Object.values(events).forEach((item) => {
          window.removeEventListener(item, handleListener);
        });
    }
  }, [userToken]);

  const handleListener = () => {
    resetTimer();
    if (userTokenRef.current) {
      handleLogoutTimer();
    }
  };

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    if (userTokenRef.current) {
      window.location.href = "/logout";
    }
  };

  return children;
};

export default SessionTimeout;
