import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";

import { CURRENCY_SYMBOL } from "../../constants";

import { ReactComponent as EditIcon } from "../../assets/images/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete2.svg";
import { ReactComponent as ArView } from "../../assets/images/ar.svg";

const symbol = CURRENCY_SYMBOL;

const MenuItem = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorDescEl, setAnchorDescEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(false);

  useEffect(() => {
    setSelectedItem(props.selected);
  }, [props.selected]);

  const onMenuClick = (event) => {
    if (props.showMenuPopup) {
      if (anchorEl == null) {
        setAnchorEl(event.currentTarget);
      } else {
        setAnchorEl(null);
      }
      setAnchorDescEl(null);
    } else {
      if (props.allowSelect) {
        setSelectedItem(!selectedItem);
      }
      props.onSelect(props.data);
    }
  };

  const handlePopoverClose = (event) => {
    setAnchorEl(null);
    setAnchorDescEl(null);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(null);
    setAnchorDescEl(event.currentTarget);
  };

  let data = props.data;

  return (
    <Grid
      item
      xs={12}
      md={3}
      onMouseLeave={handlePopoverClose}
      sx={{
        cursor: "pointer",
        position: "relative",
        justifyContent: props.firstItem
          ? "left"
          : props.lastItem
          ? "right"
          : "center",
        display: "flex",
      }}
    >
      <Card
        elevation={0}
        sx={styles.mainCard}
        style={{
          border: `1px solid ${selectedItem ? "#D22D2D" : "transparent"}`,
        }}
        onClick={onMenuClick}
      >
        <Box sx={{ position: "relative", backgroundColor: "#EEE" }}>
          <CardMedia
            component="img"
            height="130"
            image={data.images[0]}
            style={{ borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
          />
          {data.modelId && (
            <Box sx={styles.likeIconBox}>
              <ArView width={25} height={25} />
            </Box>
          )}
          {/*<Box sx={styles.likeIconBox}>*/}
          {/*  <Like width={30} height={30}/>*/}
          {/*</Box>*/}
        </Box>

        <CardActions disableSpacing>
          <CardContent sx={styles.cardContent}>
            <Typography variant="body2" sx={styles.nameText}>
              {data.name}
            </Typography>
            <Box variant="body2" sx={{ position: "relative" }}>
              <Typography
                sx={styles.descText}
                aria-owns={"mouse-over-popover" + data._id}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                {data.description}
              </Typography>
              <Popover
                id={"mouse-over-popover" + data._id}
                style={{ position: "absolute", zIndex: 11 }}
                sx={{
                  pointerEvents: "none",
                }}
                open={Boolean(anchorDescEl)}
                anchorEl={anchorDescEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography
                  sx={{
                    p: 1,
                    fontSize: 10,
                    fontFamily: "GilroyRegular",
                    maxWidth: {
                      xs: window.innerWidth / 1.5,
                      sm: window.innerWidth / 3.5,
                    },
                  }}
                >
                  {data.description}
                </Typography>
              </Popover>
            </Box>
            <Typography variant="body2" sx={styles.priceText}>
              {symbol[data.price.currency]}
              {data.price.value}
            </Typography>
          </CardContent>
        </CardActions>
      </Card>

      <Popover
        sx={{ zIndex: 11 }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <Paper
          sx={{
            flexDirection: "column",
            display: "flex",
            alignItems: "flex-start",
          }}
          onMouseLeave={handlePopoverClose}
        >
          <Button
            onClick={() => props.editMenu(data)}
            sx={[styles.btnText, styles.editBtnText]}
          >
            <EditIcon />
            <Typography px={2} py={1}>
              Edit
            </Typography>
          </Button>
          <Button
            onClick={() => props.deleteMenu(data)}
            sx={[styles.btnText, styles.deleteBtnText]}
          >
            <DeleteIcon />
            <Typography px={2} py={1}>
              Delete
            </Typography>
          </Button>
        </Paper>
      </Popover>
    </Grid>
  );
};

export default MenuItem;

const styles = {
  mainCard: {
    backgroundColor: "#FFF",
    width: { md: "95%", xs: "100%" },
    marginBottom: 2,
    borderRadius: 3,
    boxShadow: "0px 0px 15px 0px #00000014",
  },
  likeIconBox: {
    position: "absolute",
    bottom: 0,
    right: 7,
  },
  cardContent: {
    width: "100%",
    paddingBottom: "0px !important",
    padding: 0,
    mt: 1,
    px: 1,
  },
  nameText: {
    color: "#000",
    fontFamily: "GilroyBold",
    fontSize: 14,
  },
  descText: {
    color: "#999999",
    fontFamily: "GilroyRegular",
    fontSize: 12,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    mr: 4,
    maxWidth: {
      xs: window.innerWidth / 1.5,
      sm: window.innerWidth / 7,
    },
  },
  priceText: {
    color: "#000",
    fontSize: 16,
    fontFamily: "GilroyBold",
  },
  btnText: {
    px: 2,
    minWidth: 130,
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#fff !important",
    },
  },
  editBtnText: {
    color: "#000",
  },
  deleteBtnText: {
    color: "#FE724C",
  },
};
