import React, { useEffect, useState, forwardRef } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useNavigate, useLocation } from "react-router-dom";

import * as Api from "../../api";

import { connect } from "react-redux";

import Container from "../../components/Container";
import MapView from "../../components/MapView";
import AddRestaurant from "../../components/restaurant/AddRestaurant";

import { THEME_COLOR, LOADING_COLOR, THEME_COLOR4 } from "../../constants";

import "../../assets/css/custom.css";

import { ReactComponent as LocationIcon } from "../../assets/images/location.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone.svg";
import { ReactComponent as EditIcon } from "../../assets/images/edit_pencil.svg";
import { ReactComponent as DeleteIcon } from "../../assets/images/delete_white.svg";
import BannerImg from "../../assets/images/banner.png";
import RestaurantMenubar from "../../components/restaurant/RestaurantMenubar";
import ConfirmAlert from "../../components/restaurant/ConfirmAlert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const theme = createTheme({
  palette: {
    loading_color: {
      main: LOADING_COLOR,
    },
  },
});

const RestaurantInfo = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [restName, setRestName] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [cuisine, setCuisine] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [restDesc, setRestDesc] = useState("");
  const [openHr, setOpenHr] = useState("");
  const [options, setOptions] = useState("");
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [images, setImages] = useState([]);
  const [resData, setResData] = useState("");
  const [drawerAddRestroOpen, setDrawerAddRestroOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [restaurantId, setRestaurantId] = useState(
    location.pathname.split("/").length == 4
      ? location.pathname.split("/")[2]
      : ""
  );
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    getRestaurantData();
  }, []);

  useEffect(() => {
    getRestaurantCategoryList();
  }, []);

  useEffect(() => {
    if (categoryList.length && resData != "") {
      let category = categoryList.filter((x) => x._id == resData?._idCategory);
      if (category.length) {
        setCuisine(category[0]?.name);
      }
    }
  }, [categoryList, resData]);

  const getRestaurantData = () => {
    Api.getRestaurantData(restaurantId).then((response) => {
      if (response.success) {
        setRestroData(response.data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const getRestaurantCategoryList = () => {
    Api.getRestaurantCategoryList("all").then((response) => {
      if (response.success) {
        let data = response.data.rows;
        setCategoryList(data);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const setRestroData = (response) => {
    let coords = response.address.coords;
    setRestName(response.name);
    setAddress(response.address);
    setMobile(response.contactNumber);
    setEmail(response.email);
    setRestDesc(response.description);
    setOpenHr(response.openHours);
    setCenter({ lat: parseFloat(coords[0]), lng: parseFloat(coords[1]) });
    setOptions(response.options);
    setImages(response.images);
    let res_data = {
      ...response,
      name: response.name,
      email: response.email,
      address: response.address,
      mobile: response.contactNumber,
      description: response.description,
      category: response._idCategory,
      openHours: response.openHours,
      options: response.options,
      images: response.images,
      logo: response.logo,
      _idCategory: response._idCategory,
    };
    setResData(res_data);
    setDrawerAddRestroOpen(false);
  };

  const onCloseAlertMsg = () => {
    setMsgAlert({ open: false, message: "", msgType: "error" });
  };

  const toggleDrawer = (open) => {
    setDrawerAddRestroOpen(open);
  };

  const deleteRestaurant = () => {
    setConfirmBtnLoading(true);
    Api.deleteRestaurant(restaurantId).then((response) => {
      if (response.success) {
        navigate("/restaurants");
      } else {
        setConfirmBtnLoading(false);
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
    });
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {loading ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: window.innerHeight - 150 }}
        >
          <ThemeProvider theme={theme}>
            <CircularProgress color="loading_color" />
          </ThemeProvider>
        </Grid>
      ) : (
        <Grid>
          <Box sx={styles.headerDiv}>
            <Typography sx={styles.titleText}>Restaurant Info</Typography>
            <Box sx={styles.btnView}>
              <Box
                sx={styles.editInfoDiv}
                mr={1}
                onClick={() => setConfirmDelete(true)}
              >
                <DeleteIcon width={14} height={14} />
                <Typography sx={styles.editInfoText} ml={0.5}>
                  Delete
                </Typography>
              </Box>
              <Box sx={styles.editInfoDiv} onClick={() => toggleDrawer(true)}>
                <EditIcon width={14} height={14} />
                <Typography sx={styles.editInfoText} ml={0.5}>
                  Edit Info
                </Typography>
              </Box>
            </Box>
          </Box>
          <Grid
            container
            direction={"row"}
            justifyContent={"space-between"}
            mt={3}
          >
            <Grid container direction={"column"} item md={8} xs={12}>
              {images.length === 1 || images.length === 2 ? (
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  {images.length === 1 ? (
                    <Grid item xs={12} sx={styles.restSingleImg1}>
                      <img alt="" src={images[0]} style={styles.imgStyle} />
                    </Grid>
                  ) : (
                    <Grid item xs={5.8} sx={styles.restTwoImg1}>
                      <img alt="" src={images[0]} style={styles.imgStyle} />
                    </Grid>
                  )}
                  {images.length === 2 ? (
                    <Grid item xs={5.8} mr={0.4} sx={styles.restTwoImg1}>
                      <img alt="" src={images[1]} style={styles.imgStyle} />
                    </Grid>
                  ) : null}
                </Grid>
              ) : images.length > 1 ? (
                <Grid
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={7.5} sx={styles.restImg1}>
                    <img alt="" src={images[0]} style={styles.imgStyle} />
                  </Grid>
                  <Grid
                    container
                    direction={"column"}
                    justifyContent={"space-between"}
                    item
                    xs={4.2}
                    sx={{ height: { md: window.innerWidth / 4.5, xs: 150 } }}
                  >
                    <Grid sx={styles.restImg2}>
                      <img alt="" src={images[1]} style={styles.imgStyle} />
                    </Grid>
                    <Grid sx={styles.restImg3}>
                      <Box
                        sx={[
                          styles.morePhotoGrid,
                          images.length > 3 ? styles.morePhotoSubGrid : "",
                        ]}
                      >
                        {images.length > 3 ? (
                          <Typography sx={styles.photoText}>
                            +{images.length - 2} Photos
                          </Typography>
                        ) : null}
                      </Box>
                      <img alt="" src={images[2]} style={styles.imgStyle} />
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              <Grid mt={images.length > 0 ? 2 : 0.5} style={{ flexGrow: 1 }}>
                <Paper elevation={0} sx={styles.restInfo}>
                  <Grid>
                    <Typography sx={styles.restTitleText}>
                      {restName}
                    </Typography>
                    <Grid
                      item
                      xs={12}
                      container
                      direction={"row"}
                      mt={2}
                      alignItems={"center"}
                    >
                      <Grid item xs={9}>
                        <Grid container direction={"row"} item xs={12}>
                          <Box sx={styles.iconBox}>
                            <LocationIcon width={20} height={20} />
                          </Box>
                          <Typography sx={styles.restAddressText}>
                            {address.firstLine}, {address.secondLine},{" "}
                            {address.city}, {address.countryCode},{" "}
                            {address.postCode}
                          </Typography>
                        </Grid>
                        <Grid container direction={"row"} item xs={12} mt={0.5}>
                          <Box sx={styles.iconBox}>
                            <PhoneIcon width={20} height={20} />
                          </Box>
                          <Typography sx={styles.restAddressText}>
                            {mobile} - {email}
                          </Typography>
                        </Grid>
                      </Grid>
                      {options.hasDineIn || options.hasTakeAway ? (
                        <Grid item xs={3}>
                          <Box sx={styles.takeAwayBtn}>
                            {options.hasDineIn && options.hasTakeAway
                              ? "Dine In & Take Away"
                              : options.hasDineIn && !options.hasTakeAway
                              ? "Dine In"
                              : "Take Away"}
                          </Box>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid mt={2}>
                    <Typography sx={styles.restCuisineDescTitleText}>
                      Cuisine
                    </Typography>
                    <Box sx={styles.borderBottomLine} />
                    <Typography sx={styles.restCuisineDescText}>
                      {cuisine}
                    </Typography>
                  </Grid>
                  <Grid sx={{ marginTop: 3 }}>
                    <Typography sx={styles.restCuisineDescTitleText}>
                      Description
                    </Typography>
                    <Box sx={styles.borderBottomLine} />
                    <Typography sx={styles.restCuisineDescText}>
                      {restDesc}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
            <Grid item md={3.8} xs={12} sx={{ marginTop: { md: 0, xs: 3 } }}>
              <Paper elevation={0} sx={styles.rightContentGrid}>
                <Box sx={styles.mapGrid}>
                  <MapView
                    center={center}
                    zoom={15}
                    address={address}
                    restName={restName}
                    type={"info"}
                  />
                </Box>
                <Box mt={2}>
                  <Typography sx={styles.openHrText}>Opening Hours</Typography>
                  <Box sx={styles.borderBottomLine} />
                  <Box mt={1.5}>
                    {openHr
                      ? Object.keys(openHr).map((key, index) => {
                          if (
                            key === "sunday" ||
                            key === "monday" ||
                            key === "tuesday" ||
                            key === "wednesday" ||
                            key === "thursday" ||
                            key === "friday" ||
                            key === "saturday"
                          ) {
                            return (
                              <Grid
                                key={index}
                                container
                                direction={"row"}
                                mt={1}
                                justifyContent={"space-between"}
                              >
                                <Typography sx={styles.dayText}>
                                  {key}
                                </Typography>
                                {openHr[key].isOpen ? (
                                  <Typography sx={styles.openTimeText}>
                                    {moment(
                                      openHr[key].timings[0]["from"],
                                      "H:mm"
                                    ).format("h:mm A")}
                                    -
                                    {moment(
                                      openHr[key].timings[0]["to"],
                                      "H:mm"
                                    ).format("h:mm A")}
                                  </Typography>
                                ) : (
                                  <Typography sx={styles.closedText}>
                                    Closed
                                  </Typography>
                                )}
                              </Grid>
                            );
                          }
                        })
                      : null}
                  </Box>
                </Box>
                <Box mt={1}>
                  <img alt="" src={BannerImg} style={styles.imgStyle} />
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <Snackbar
            open={msgAlert.open}
            autoHideDuration={6000}
            onClose={onCloseAlertMsg}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              onClose={onCloseAlertMsg}
              severity={msgAlert.msgType}
              sx={{ width: "100%" }}
            >
              {msgAlert.message}
            </Alert>
          </Snackbar>
        </Grid>
      )}
      <SwipeableDrawer
        anchor={"right"}
        open={drawerAddRestroOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <Box
          sx={{
            width: { xs: window.innerWidth, md: window.innerWidth / 1.5 },
            height: "100%",
          }}
          mt={4}
        >
          <AddRestaurant
            type={"edit"}
            resData={resData}
            restaurantId={restaurantId}
            categoryList={categoryList}
            onSubmit={setRestroData}
          />
        </Box>
      </SwipeableDrawer>
      <ConfirmAlert
        confirmDelete={confirmDelete}
        title={"Are you sure?"}
        content={"You will not be able to recover this restaurant"}
        firstBtn={"No"}
        secondBtn={confirmBtnLoading ? "Please Wait" : "Yes ! Delete it"}
        btnLoading={confirmBtnLoading}
        onCloseConfirmDelete={() => setConfirmDelete(false)}
        onConfirmDelete={deleteRestaurant}
      />
    </Container>
  );
};

export default connect(null, null)(RestaurantInfo);

const styles = {
  headerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleText: {
    color: "#000",
    fontSize: 20,
    fontFamily: "GilroyBold",
  },
  btnView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  editInfoDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000",
    borderRadius: 20,
    cursor: "pointer",
    px: 1.5,
    py: 0.8,
    height: 36,
    minWidth: 100,
  },
  editInfoText: {
    color: "#FFF",
    fontSize: 12.5,
    fontFamily: "GilroySemiBold",
    marginLeft: 1,
  },
  restImg1: {
    height: { md: window.innerWidth / 4.5, xs: 150 },
    overflow: "hidden",
    borderRadius: 3,
  },
  restTwoImg1: {
    height: { md: window.innerWidth / 5, xs: 150 },
    overflow: "hidden",
    borderRadius: 3,
  },
  restSingleImg1: {
    height: { md: window.innerWidth / 3, xs: "100%" },
    overflow: "hidden",
    borderRadius: 3,
  },
  restImg2: {
    height: "48%",
    overflow: "hidden",
    borderRadius: 3,
  },
  restImg3: {
    height: "48%",
    position: "relative",
    overflow: "hidden",
    borderRadius: 3,
  },
  imgStyle: {
    height: "100%",
    width: "100%",
  },
  morePhotoGrid: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  morePhotoSubGrid: {
    backgroundColor: "rgba(0,0,0,0.6)",
  },
  photoText: {
    color: "#fff",
    fontSize: 12,
    fontFamily: "GilroySemiBold",
  },
  restInfo: {
    width: "100%",
    height: "100%",
    borderRadius: 3,
    px: 2,
    py: 2.5,
  },
  restTitleText: {
    fontFamily: "GilroyBold",
  },
  iconBox: {
    width: 42,
  },
  restAddressText: {
    color: "#888",
    fontSize: 12,
    fontFamily: "GilroySemiBold",
  },
  restCuisineDescTitleText: {
    fontSize: 12,
    fontFamily: "GilroyBold",
  },
  restCuisineDescText: {
    fontSize: 12,
    fontFamily: "GilroySemiBold",
    my: 2,
  },
  borderBottomLine: {
    borderTop: `3px solid ${THEME_COLOR}`,
    width: 25,
    marginLeft: 0.3,
  },
  takeAwayBtn: {
    width: "fit-content",
    border: "1.4px solid #000",
    borderRadius: 5,
    fontSize: 11,
    fontFamily: "GilroySemiBold",
    textAlign: "center",
    px: 2,
    py: 0.2,
  },
  rightContentGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "100%",
    borderRadius: 3,
    px: 1.5,
    pt: 1.5,
    pb: 0,
  },
  mapGrid: {
    position: "relative",
    overflow: "hidden",
    borderRadius: 3,
    width: "100%",
    height: 250,
  },
  openHrText: {
    fontSize: 12,
    fontFamily: "GilroyBold",
  },
  dayText: {
    fontSize: 12,
    fontFamily: "GilroyMedium",
    width: 100,
  },
  openTimeText: {
    fontSize: 12,
    fontFamily: "GilroyBold",
  },
  closedText: {
    fontSize: 12,
    fontFamily: "GilroyBold",
    color: "#ff5500",
  },
  mapLocationBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    margin: 0,
  },
};
