import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Dropzone from "react-dropzone";

import "./../assets/css/custom.css";

import { THEME_COLOR3 } from "../constants";

import { ReactComponent as UploadIcon } from "../assets/images/upload.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close.svg";

const UploadArModel = (props) => {
  const menuModelRef = useRef();
  let {
    lighting,
    modelZoom,
    horizontalTiltAngle,
    verticalTiltAngle,
    exposure,
    cameraTargetYAxis,
  } = props;

  const [arModel, setArModel] = useState(props.arModel);

  useEffect(() => {
    setArModel(props.arModel);
  }, [props.arModel]);

  useEffect(() => {
    if (menuModelRef.current) {
      if (menuModelRef.current?.modelIsVisible) {
      } else {
        let interval = setInterval(progress, 100);
        function progress() {
          if (menuModelRef.current?.modelIsVisible) {
            clearInterval(interval);
            updateLighting();
          }
        }
      }
    }
  }, [arModel]);

  useEffect(() => {
    if (menuModelRef.current) {
      setArModel(null);
      setTimeout(() => {
        setArModel(props.arModel);
      }, 100);
    }
  }, [modelZoom]);

  useEffect(() => {
    if (menuModelRef.current) {
      if (menuModelRef.current?.cameraOrbit) {
        menuModelRef.current.cameraOrbit = `${horizontalTiltAngle}deg ${verticalTiltAngle}deg 105%`;
      }
    }
  }, [horizontalTiltAngle, verticalTiltAngle]);

  useEffect(() => {
    if (menuModelRef.current) {
      if (menuModelRef.current?.cameraTarget) {
        menuModelRef.current.cameraTarget = `auto ${Number(
          cameraTargetYAxis
        )}m auto`;
      }
    }
  }, [cameraTargetYAxis]);

  useEffect(() => {
    updateLighting();
  }, [lighting, exposure]);

  const updateLighting = () => {
    if (menuModelRef.current) {
      let value = lighting;
      let material = menuModelRef.current?.model?.materials?.[0];
      if (value?.length == 3) {
        if (material?.emissiveTexture) {
          material.emissiveTexture.setTexture(
            material.pbrMetallicRoughness.baseColorTexture.texture
          );
          material.setEmissiveFactor(value);
        }
      } else {
        if (material) {
          material.setEmissiveFactor([0, 0, 0]);
        }
      }
    }
  };

  const uploadFile = (acceptedFiles) => {
    acceptedFiles.map((file) => {
      if (file.name.split(".").pop() != "glb") {
        props.uploadError("Upload glb file");
        return;
      }

      let fileSize = file.size;
      let sizeMb = (fileSize / (1024 * 1024)).toFixed(3);
      let image = URL.createObjectURL(file);
      let new_id = new Date();
      let data = {
        id: new_id,
        name: file.name,
        displayName: file.name,
        size: sizeMb,
        image: image,
        upload: false,
      };
      props.uploadFile(data, file);
    });
  };

  const uploadError = (err) => {
    console.log("err", err);
    if (err.length) {
      if (err[0]["errors"].length)
        if (err[0]["errors"][0]["code"] == "too-many-files") {
          if (props.maxFiles == 1) {
            props.uploadError("Only 1 image is allowed to upload");
          } else {
            props.uploadError(
              `Only ${props.maxFiles} images are allowed to upload at a time`
            );
          }
        } else {
          props.uploadError(err[0]["errors"][0]["message"]);
        }
    }
  };

  let upload_view = arModel == "";

  const ModelAR = React.useMemo(() => {
    return arModel ? (
      <model-viewer
        ref={menuModelRef}
        style={{
          width: "100%",
          height: "100%",
          zIndex: 3,
          backgroundColor: "transparent",
        }}
        loading={"eager"}
        touch-action="none"
        exposure={exposure}
        src={arModel}
        disable-tap
        alt=""
        ar-modes="webxr scene-viewer quick-look"
        max-camera-orbit="Infinity 90deg auto"
        camera-orbit={`${horizontalTiltAngle}deg ${verticalTiltAngle}deg 105%`}
        field-of-view={modelZoom ? `${Number(modelZoom)}deg` : "auto"}
        ar
        auto-rotate
        camera-controls
        camera-target={`auto ${cameraTargetYAxis}m auto`}
      ></model-viewer>
    ) : null;
  }, [arModel, exposure]);

  return (
    <Dropzone
      maxFiles={props.maxFiles}
      onDropRejected={(err) => uploadError(err)}
      onDrop={uploadFile}
    >
      {({ getRootProps, getInputProps }) => (
        <Grid
          item
          xs={12}
          container
          direction="column"
          className={
            upload_view ? (!props.imageLoading ? "cursor-pointer" : "") : ""
          }
          style={{
            height: window.innerHeight - (upload_view ? 250 : 120),
            alignItems: "center",
          }}
          sx={
            upload_view
              ? [
                  {
                    border: "1.5px solid " + THEME_COLOR3,
                    height: "fit-content",
                    py: 3,
                  },
                  styles.imageUploadGrid,
                ]
              : null
          }
          {...(upload_view
            ? !props.imageLoading
              ? getRootProps()
              : null
            : null)}
        >
          {upload_view ? null : (
            <Grid
              style={{
                width: window.innerWidth / 4.5,
                height: "100%",
                backgroundColor: "#EEE",
                position: "relative",
              }}
            >
              {props?.bgImage ? (
                <img
                  alt=""
                  src={props?.bgImage}
                  slot="poster"
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                  }}
                />
              ) : null}
              {ModelAR}
            </Grid>
          )}
          {upload_view ? (
            <>
              {!props.imageLoading ? <input {...getInputProps()} /> : null}
              <UploadIcon
                height={50}
                width={50}
                style={{ marginTop: 10, marginBottom: 15 }}
              />
              <Typography sx={styles.uploadText}>{props.uploadText}</Typography>
              <Typography sx={styles.uploadText}>OR</Typography>
              <label htmlFor="contained-button-file">
                <Grid
                  className="image-browse-grid"
                  sx={{ border: "2px solid " + THEME_COLOR3 }}
                >
                  <Typography
                    className="image-browse-text"
                    sx={{ fontFamily: "GilroyBold" }}
                  >
                    {props.imageLoading ? "Please wait" : "Browse"}
                  </Typography>
                </Grid>
              </label>
            </>
          ) : null}
        </Grid>
      )}
    </Dropzone>
  );
};

export default UploadArModel;

const styles = {
  imageUploadGrid: {
    position: "relative",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "dashed !important",
    borderRadius: "10px",
  },
  uploadText: {
    color: THEME_COLOR3,
    fontFamily: "GilroyRegular",
    fontSize: 12,
    mb: 2,
  },
  browseText: {
    fontFamily: "GilroyBold",
    fontSize: 12,
  },
  closeIcon: {
    backgroundColor: "red",
    borderRadius: 8,
    width: 18,
    height: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    top: -4,
    right: -4,
  },
  uploading: {
    backgroundColor: "rgba(0,0,0, 0.3)",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    borderRadius: "10px",
  },
  uploadingText: {
    color: "#FFF",
    fontSize: 15,
    fontFamily: "GilroyMedium",
  },
};
