import React, {useEffect, useRef, useState} from 'react';
import {Wrapper} from '@googlemaps/react-wrapper';
import {API_KEY} from "../constants";

const mapStyle = [
  {
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

const Map = (props) => {
  const ref = useRef();
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (map) {
      map.setCenter(props.center);
      map.setZoom(props.zoom);
      map.setOptions({
        styles: mapStyle,
        fullscreenControl: false,
        zoomControl: false,
        // gestureHandling: 'none',
      });
    }
  }, [map, props.center, props.zoom]);

  useEffect(() => {
    const map1 = new window.google.maps.Map(ref.current);
    setMap(map1);
  }, []);

  return (
    <div ref={ref} id="mainMapView" style={{height: '100%', width: '100%'}}>
      <Marker position={props.center} map={map} address={props.location} restName={props.restaurantName}
              type={props.type_val}/>
    </div>
  );
};

const Marker = (props) => {
  const [marker, setMarker] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);

  useEffect(() => {
    if (props.map) {
      let marker1 = new window.google.maps.Marker({
        map: props.map,
      });
      marker1.setIcon({
        path: 'M17.657 5.304c-3.124-3.073-8.189-3.073-11.313 0-3.124 3.074-3.124 8.057 0 11.13l5.656 5.565 5.657-5.565c3.124-3.073 3.124-8.056 0-11.13zm-5.657 8.195c-.668 0-1.295-.26-1.768-.732-.975-.975-.975-2.561 0-3.536.472-.472 1.1-.732 1.768-.732s1.296.26 1.768.732c.975.975.975 2.562 0 3.536-.472.472-1.1.732-1.768.732z',
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(12, 20),
        fillColor: "black",
        fillOpacity: 0.9,
        scale: 2,
        strokeColor: "black",
        strokeWeight: 2,
      });
      setMarker(marker1);
    }
  }, [props.map]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        position: props.position,
      });
    }
  }, [marker, props.position]);

  useEffect(() => {
    if (props.type === 'info') {
      let html = `<div class='address-div'>
          <div class='rest-name'>` + props.restName + `</div>`;

      if (props.address) {
        html += `<div
              class="address-name">
              ${props.address.firstLine}, ${props.address.secondLine}, ${props.address.city},` + (props.address.countryCode == 'IN' ? 'India' : props.address.countryCode) + `, ${props.address.postCode}
            </div>`;
      }

      html += `</div>`;


      if (infoWindow) {
        infoWindow.close();
      }

      let info_window = new window.google.maps.InfoWindow({
        content: html,
        position: props.position,
        map: props.map,
      });
      setInfoWindow(info_window);
    }
  }, [props.position]);

  return null;
};

const MapView = (props) => {
  return (
    <Wrapper apiKey={API_KEY} render={null}>
      <Map center={props.center} zoom={props.zoom} location={props.address} restaurantName={props.restName}
           type_val={props.type}/>
    </Wrapper>
  );
};

export default MapView;
