import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./UberEats.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";
import UploadFile from "../../components/UploadFile";
import RestaurantHeader from "../../components/RestaurantHeader";

import { ReactComponent as JsonIcon } from "../../assets/images/json.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/close_red.svg";

const UberEats = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState("uber_eats");
  const [restaurantId, setRestaurantId] = useState("");
  const [jsonList, setJsonList] = useState([]);
  const [jsonFile, setJsonFile] = useState(null);
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    let params = location.pathname.split("/");
    if (params.length >= 4) {
      setRestaurantId(params[2]);
      setPageType(params[3]);
    }
  }, []);

  async function parseJsonFile(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onload = (event) => resolve(JSON.parse(event.target.result));
      fileReader.onerror = (error) => reject(error);
      fileReader.readAsText(file);
    });
  }

  const uploadFile = async (data, file) => {
    data["upload"] = true;
    setJsonFile(file);
    setJsonList([data]);
  };

  const uploadError = (err) => {
    setMsgAlert({ open: true, message: err, msgType: "error" });
  };

  const onRemoveFile = (file) => {
    setJsonFile(null);
    setJsonList((prev) => prev.filter((x) => x.id !== file.id));
  };

  const uploadJsonFile = async () => {
    if (jsonFile == null) {
      setMsgAlert({
        open: true,
        message: "Upload Json File",
        msgType: "error",
      });
      return;
    }
    setIsLoading(true);
    const object = await parseJsonFile(jsonFile);
    let datas = JSON.stringify(object);
    let type = pageType == "uber_eats" ? "uber" : "swiggy";
    Api.importFromUberEatsSwiggy(datas, restaurantId, type, "GBP").then(
      (response) => {
        if (response.success) {
          setMsgAlert({
            open: true,
            message: "Upload Successfully",
            msgType: "success",
          });
          setJsonFile(null);
          setJsonList([]);
        } else {
          setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        }
        setIsLoading(false);
      }
    );
  };

  return (
    <Container
      page={"restaurants"}
      searchVal={""}
      onSearch={() => null}
      searchLabel=""
    >
      {isLoading ? (
        <Loader height={{ height: window.innerHeight - 150 }} />
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={12} mb={2}>
            <RestaurantHeader
              title={`Upload Menu - ${
                pageType == "uber_eats" ? "Uber Eats" : "Swiggy"
              }`}
              onBackBtnClick={() =>
                navigate("/restaurant/" + restaurantId + "/manage_menu")
              }
            />
          </Grid>
          <Grid item xs={12} className={styles.mainView} p={3}>
            <Grid item xs={12} md={8}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                item
                xs={12}
              >
                <Grid item xs={12} md={5.8}>
                  <UploadFile
                    page={"UberEats"}
                    uploadText={"Drag and Drop Your JSON file Here"}
                    imageLoading={false}
                    maxFiles={3}
                    uploadHeight={"240px"}
                    accept={{ "application/json": [".json"] }}
                    uploadError={uploadError}
                    uploadFile={uploadFile}
                  />
                  <Typography my={2} className={styles.noteText}>
                    Note: Upload JSON file
                  </Typography>
                  <Box
                    mt={2}
                    className={styles.addBtnView}
                    onClick={() => uploadJsonFile()}
                  >
                    <Typography className={styles.addBtnText}>
                      Upload
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  container
                  direction="column"
                  item
                  xs={12}
                  md={5.8}
                  sx={{
                    marginTop: { xs: 5, md: 2 },
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  {jsonList.map((item, index) => {
                    return (
                      <Box key={index} className={styles.fileListView} mb={2}>
                        <JsonIcon width={40} height={40} />
                        <Typography className={styles.fileNameText} mx={1}>
                          {item.name}
                        </Typography>
                        <Box
                          style={{ cursor: "pointer" }}
                          onClick={() => onRemoveFile(item)}
                        >
                          <CloseIcon width={20} height={20} />
                        </Box>
                      </Box>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <AlertMsg
            msgAlert={msgAlert}
            onCloseAlertMsg={() =>
              setMsgAlert({ open: false, message: "", msgType: "error" })
            }
          />
        </Grid>
      )}
    </Container>
  );
};

export default UberEats;
