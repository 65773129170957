import * as React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { ReactComponent as SearchIcon } from "../assets/images/search_white.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close.svg";

const SearchInput = (props) => {

  let inputBg = props?.inputBg ? props.inputBg : '#FFF';

  return (
    <TextField
      fullWidth
      focused
      value={props.value}
      variant="filled"
      placeholder={props.placeholder}
      autoFocus={props.value != ""}
      hiddenLabel={true}
      onChange={(e) => props.setValue(e.target.value)}
      sx={{
        "& .MuiInputBase-root": {
          "&:hover": {
            backgroundColor: inputBg,
          },
          "&.Mui-focused": {
            backgroundColor: inputBg,
          },
          "&.Mui-disabled": {
            backgroundColor: inputBg,
          },
        },
      }}
      InputProps={{
        disableUnderline: true,
        autoComplete: "none",
        sx: {
          backgroundColor: inputBg,
          fontSize: 12,
          fontFamily: "GilroySemiBold",
          height: props?.height ? props.height : 40,
          borderRadius: 2,
          width: "100%",
        },
        startAdornment: (
          <InputAdornment
            position="start"
            sx={{ marginLeft: 1, cursor: "pointer" }}
          >
            <SearchIcon height={15} width={15} />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {props.value != "" ? (
              <InputAdornment
                onClick={() => props.setValue("")}
                position="end"
                sx={{ marginLeft: 1, cursor: "pointer" }}
              >
                <CloseIcon height={15} width={15} />
              </InputAdornment>
            ) : null}
            {props?.rightView ? props?.rightView : null}
          </>
        ),
      }}
    />
  );
};

export default SearchInput;
