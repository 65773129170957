import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import RestaurantBtn from "./RestaurantBtn";
import AlertMsg from "./AlertMsg";
import MapView from "./MapView";

import { THEME_COLOR, THEME_COLOR3, THEME_COLOR4 } from "../constants";

import "./../assets/css/custom.css";

const theme = createTheme({
  palette: {
    gray: {
      main: THEME_COLOR4,
    },
    yellow: {
      main: THEME_COLOR,
    },
  },
});

const AddRestaurantLocation = (props) => {
  const [firstLine, setFirstLine] = useState("");
  const [secondLine, setSecondLine] = useState("");
  const [postcode, setPostcode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    setFirstLine(props.firstLine);
    setSecondLine(props.secondLine);
    setPostcode(props.postcode);
    setCity(props.city);
    setLat(props.lat);
    setLng(props.lng);
    setCountry(props.country);
  }, [props]);

  const onNextBtnClick = (e) => {
    e.preventDefault();
    let data = {
      firstLine: firstLine,
      secondLine: secondLine,
      postCode: postcode,
      city: city,
      country: country,
      lat: lat,
      lng: lng,
    };

    if (/[^0-9a-zA-Z ]/g.test(data.postCode)) {
      setMsgAlert({
        open: true,
        message: "Invalid Postcode",
        msgType: "error",
      });
      return;
    }

    if (
      floatNumCheck(data.lat) == "" ||
      floatNumCheck(data.lat) == "+" ||
      floatNumCheck(data.lat) == "-"
    ) {
      setMsgAlert({
        open: true,
        message: "Invalid Latitude",
        msgType: "error",
      });
      return;
    }

    if (
      floatNumCheck(data.lng) == "" ||
      floatNumCheck(data.lng) == "+" ||
      floatNumCheck(data.lng) == "-"
    ) {
      setMsgAlert({
        open: true,
        message: "Invalid Longitude",
        msgType: "error",
      });
      return;
    }

    props.resLocation(data);
  };

  const floatNumCheck = (val) => {
    let sign = "";
    val = val.toString();
    if (val.length >= 1) {
      if (val[0] == "+" || val[0] == "-") {
        sign = val[0];
      }
    }
    val = val.replace(/[^0-9\.]/g, "");
    if (val.split(".").length > 2) {
      val = val.replace(/\.+$/, "");
    }
    return sign + val;
  };

  return (
    <Grid component="form" autoComplete="off" onSubmit={onNextBtnClick}>
      <Grid
        container
        direction="row"
        item
        xs={12}
        justifyContent="space-between"
      >
        <Grid item xs={12} md={5.3} mt={2}>
          <Grid>
            <ThemeProvider theme={theme}>
              <TextField
                focused
                fullWidth
                required
                color="gray"
                id="firstLine"
                label="First Line"
                variant="standard"
                value={firstLine}
                onChange={(e) => setFirstLine(e.target.value)}
                InputLabelProps={{
                  style: { color: THEME_COLOR3, fontFamily: "GilroyRegular" },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: { fontSize: 14, fontFamily: "GilroySemiBold" },
                }}
              />
            </ThemeProvider>
          </Grid>

          <Grid mt={4}>
            <ThemeProvider theme={theme}>
              <TextField
                focused
                fullWidth
                required
                color="gray"
                id="secondLine"
                label="Second Line"
                variant="standard"
                value={secondLine}
                onChange={(e) => setSecondLine(e.target.value)}
                InputLabelProps={{
                  style: { color: THEME_COLOR3, fontFamily: "GilroyRegular" },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: { fontSize: 14, fontFamily: "GilroySemiBold" },
                }}
              />
            </ThemeProvider>
          </Grid>

          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} md={5.5} mt={4}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  required
                  color="gray"
                  id="city"
                  label="City"
                  variant="standard"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  InputLabelProps={{
                    style: { color: THEME_COLOR3, fontFamily: "GilroyRegular" },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { fontSize: 14, fontFamily: "GilroySemiBold" },
                  }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} md={5.5} mt={4}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  required
                  color="gray"
                  id="country"
                  label="Country"
                  variant="standard"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  InputLabelProps={{
                    style: { color: THEME_COLOR3, fontFamily: "GilroyRegular" },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { fontSize: 14, fontFamily: "GilroySemiBold" },
                  }}
                />
              </ThemeProvider>
            </Grid>
          </Grid>

          <Grid mt={4}>
            <ThemeProvider theme={theme}>
              <TextField
                focused
                fullWidth
                required
                color="gray"
                id="postcode"
                label="Postcode"
                variant="standard"
                value={postcode}
                onChange={(e) =>
                  setPostcode(e.target.value.replace(/[^0-9a-zA-Z ]/g, ""))
                }
                InputLabelProps={{
                  style: { color: THEME_COLOR3, fontFamily: "GilroyRegular" },
                }}
                inputProps={{
                  autoComplete: "none",
                  sx: { fontSize: 14, fontFamily: "GilroySemiBold" },
                }}
              />
            </ThemeProvider>
          </Grid>

          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={12} md={5.5} mt={4}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  required
                  color="gray"
                  id="latitude"
                  label="Latitude"
                  variant="standard"
                  value={lat.toString()}
                  onChange={(event) =>
                    setLat(floatNumCheck(event.target.value))
                  }
                  InputLabelProps={{
                    style: { color: THEME_COLOR3, fontFamily: "GilroyRegular" },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { fontSize: 14, fontFamily: "GilroySemiBold" },
                  }}
                />
              </ThemeProvider>
            </Grid>
            <Grid item xs={12} md={5.5} mt={4}>
              <ThemeProvider theme={theme}>
                <TextField
                  focused
                  fullWidth
                  required
                  color="gray"
                  id="longitude"
                  label="Longitude"
                  variant="standard"
                  InputLabelProps={{
                    style: { color: THEME_COLOR3, fontFamily: "GilroyRegular" },
                  }}
                  inputProps={{
                    autoComplete: "none",
                    sx: { fontSize: 14, fontFamily: "GilroySemiBold" },
                  }}
                  value={lng.toString()}
                  onChange={(event) =>
                    setLng(floatNumCheck(event.target.value))
                  }
                />
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={5.3} mt={2}>
          <Grid
            sx={{
              borderRadius: 3,
              overflow: "hidden",
              width: "100%",
              height: "100%",
              minHeight: 200,
              position: "relative",
            }}
          >
            <MapView
              center={{
                lat: lat == "" ? 0 : parseFloat(lat),
                lng: lng == "" ? 0 : parseFloat(lng),
              }}
              zoom={15}
              address={""}
              restName={""}
              type={"location"}
            />
          </Grid>
        </Grid>
      </Grid>
      <RestaurantBtn
        activeTab={1}
        loading={false}
        previousBtnClick={props.previousBtnClick}
      />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Grid>
  );
};

export default AddRestaurantLocation;
