export default function getUserLocation(coords) {
  return fetch(
    "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
      coords.latitude +
      "," +
      coords.longitude +
      "&key=" +
      process.env.REACT_APP_MAP_API_KEY
  )
    .then((response) => response.json())
    .then((responseJson) => {
      return {
        success: true,
        msg: "success",
        data: getAddress(responseJson),
      };
    })
    .catch(function (error) {
      console.log(
        "There has been a problem with your fetch operation: " + error.message
      );
      if (typeof error.response !== "undefined") {
        if (typeof error.response.data !== "undefined") {
          return {
            success: false,
            msg: error.response.data.msg,
          };
        }
      }
      return {
        success: false,
        msg: "Location Fetch Error",
      };
    });
}

const getAddress = (address) => {
  let pin_code_val = "";
  let city_val = "";
  let country_val = "";
  let country_code = "";
  let locationResponse = address?.results?.[0]?.address_components;
  let locationAddress =
    (locationResponse.length >= 1 ? locationResponse[0].long_name : "") +
    (locationResponse.length >= 2 ? ", " + locationResponse[1].long_name : "") +
    (locationResponse.length >= 3 ? ", " + locationResponse[2].long_name : "");

  locationResponse.map((y, i) => {
    if (y.types.indexOf("postal_code") > -1) {
      pin_code_val = y.long_name;
    } else if (y.types.indexOf("country") > -1) {
      country_val = y.long_name;
      country_code = y.short_name;
    }

    if (city_val == "") {
      if (y.types.indexOf("administrative_area_level_7") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("administrative_area_level_6") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("administrative_area_level_5") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("administrative_area_level_4") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("administrative_area_level_3") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("administrative_area_level_2") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("administrative_area_level_1") > -1) {
        city_val = y.long_name;
      }
    }

    if (city_val == "") {
      if (y.types.indexOf("sublocality_level_5") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("sublocality_level_4") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("sublocality_level_3") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("sublocality_level_2") > -1) {
        city_val = y.long_name;
      }
      if (y.types.indexOf("sublocality_level_1") > -1) {
        city_val = y.long_name;
      }
    }

    if (y.types.indexOf("locality") > -1) {
      city_val = y.long_name;
    }
  });

  console.log({
    address: locationAddress,
    pincode: pin_code_val,
    city: city_val,
    country: country_val,
    countryCode: country_code,
  })

  return {
    address: locationAddress,
    pincode: pin_code_val,
    city: city_val,
    country: country_val,
    countryCode: country_code,
  };
};
